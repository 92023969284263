import { useCheckout } from '@stripe/react-stripe-js';
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Box, TextField, Button } from '@mui/material';
import { styled } from "@mui/material/styles";
import { Trans } from "./Trans.js";

const CenteredBox = styled(Box)({
    alignSelf: "center",  // centers horizontally
});

const CodeButton = styled(Button)({
    backgroundColor: "#B2A494",
    fontWeight: 700, // Bold
})

const PromotionCode = forwardRef(({ onCoupon, code, saveCode }, ref) => {
    const { applyPromotionCode, removePromotionCode } = useCheckout();
    const checkout = useCheckout();
    const [draft, setDraft] = useState('');
    const [loading, setLoading] = useState(false);

    useImperativeHandle(ref, () => ({
        callChildFunction: childFunction,
    }));

    const handleChange = (e) => {
        setDraft(e.target.value);
    };
    const handleSubmit = () => {
        setLoading(true);
        applyPromotionCode(draft).finally(() => {
            setDraft('');
            onCoupon(checkout.id);
            saveCode(draft);
            setLoading(false);
        });
    };

    const childFunction = () => {
        if (code) {
            setLoading(true);
            applyPromotionCode(code).finally(() => {
                onCoupon(checkout.id);
                setLoading(false);
            });
        }
    }

    useEffect(() => {
        if (code) {
            setLoading(true);
            applyPromotionCode(code).finally(() => {
                onCoupon(checkout.id);
                setLoading(false);
            });
        }
    }, [])

    return (
        <CenteredBox display="flex" gap={2} marginTop={2}>
            <TextField
                label={<Trans text={"discount_code"} />}
                variant="outlined"
                value={draft}
                onChange={handleChange}
                size="small"
            />

            <CodeButton
                variant="contained"
                onClick={handleSubmit}
                disabled={loading}
                size="small"
            >
                <Trans text={"apply"} />
            </CodeButton>
        </CenteredBox>
    );
});

export default PromotionCode;