//COMPONENTS
import { TransUpperCase, Trans } from "./Trans.js";
import PurchaseTermsText from "./PurchaseTermsText.js";

function PurchaseTerms({ dealtype }) {
  var quoteTermsClassname = "rounded-border quote-terms";
  var quoteTermsLink = "https://hubs.ly/H0n42zC0";

  switch (dealtype) {
    case "EQUIMETRE FLAT":
      quoteTermsClassname = "rounded-border quote-terms quote-terms-equimetre";
      break;
    case "EQUIMETRE TROT":
      quoteTermsClassname = "rounded-border quote-terms quote-terms-equimetre";
      break;
    case "EQUIMETRE VET":
      quoteTermsClassname =
        "rounded-border quote-terms quote-terms-equimetre-vet";
      break;
    case "EQUISYM":
      quoteTermsClassname = "rounded-border quote-terms quote-terms-equisym";
      quoteTermsLink = "https://hubs.ly/Q01l4qmf0"
      break;
    case "Equiscience":
      quoteTermsClassname = "rounded-border quote-terms quote-terms-equiscience";
      quoteTermsLink = "https://quote.equimetre.com/202405_ARIONEO_FORMATION_CGV_ENG.pdf"
      break;
    default:
      break;
  }

  return (
    <div>
      {dealtype != "Equiscience" && (
        <PurchaseTermsText dealtype={dealtype}></PurchaseTermsText>
      )}

    </div>
  );
}

export default PurchaseTerms;
