import React from 'react';
import { useCheckout } from '@stripe/react-stripe-js';
import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { Trans } from "./Trans.js";

import { styled } from "@mui/material/styles";

const SubmitButton = styled(Button)({
    backgroundColor: "#B2A494", // Custom background color
    color: "white", // White text
    textTransform: "none", // Keeps text in normal case (not uppercase)
    padding: "6px 16px",
    fontSize: "1.2rem", // Increased font size
    fontFamily: "Lato, sans-serif", // Apply Lato font
    fontWeight: 400, // Regular
    marginTop: "16px"
});

const PayButton = ({ formData, checkout }) => {
    const { confirm } = useCheckout();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);

    const handleClick = () => {
        setLoading(true);

        fetch('/update-checkout-session', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data: formData, sessionId: checkout.id })
        }).then((response) => {
            console.log(response)
        })

        checkout.updateEmail(formData.email).then((result) => {
            if (result.error) {
                setError(result.error);
            } else {
                checkout.updateBillingAddress({
                    name: formData.firstName + " " + formData.lastName + " " + formData.company,
                    address: {
                        line1: formData.address1,
                        line2: formData.address2,
                        city: formData.city,
                        state: '',
                        postal_code: formData.zip,
                        country: formData.country
                    }
                }).then((result) => {
                    if (result.error) {
                        setError(result.error);
                    } else {
                        confirm().then((result) => {
                            if (result.type === 'error') {
                                setError(result.error)
                            }
                            setLoading(false);
                        })
                    }
                })
            }
        })

    };

    // Liste des champs qui sont requis
    const requiredFields = ["firstName", "lastName", "address1", "zip", "city", "email", "country", "phone"];


    // État qui indique si tous les champs requis sont remplis
    const [isFormComplete, setIsFormComplete] = useState(false);

    // Vérifie à chaque changement si tous les champs sont remplis
    useEffect(() => {
        // Vérifie qu’aucun des champs obligatoires n'est vide
        const allRequiredFilled = requiredFields.every(
            (field) => formData[field].trim() !== ""
        );
        setIsFormComplete(allRequiredFilled);
    }, [formData]);

    return (
        <div>
            <SubmitButton fullWidth variant="contained" color="primary" sx={{ fontFamily: "Montserrat, sans-serif", fontWeight: "700" }} onClick={handleClick} disabled={loading || !isFormComplete}>
                <Trans text={"one_time_payment"} />
            </SubmitButton>
            {error && <div>{error.message}</div>}
        </div>
    )
};

export default PayButton;