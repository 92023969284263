import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { Modal as BaseModal } from '@mui/base/Modal';
import { useState, forwardRef } from "react";
import clsx from 'clsx';
import { styled, css } from '@mui/system';

//COMPONENTS
import BillingPaymentMethodsItemsTable from "./BillingPaymentMethodsItemsTable.js";
import BillingPaymentMethodsForm from './BillingPaymentMethodsForm.js';
import { TransUpperCase, Trans } from "./Trans.js";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_kfIqOpYYnZYLHUttgqQnagC200fr8JFN6j');

function BillingPaymentMethods({ customer, paymentMethods, onPaymentMethodsChange, loadingPaymentMethods }) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const options = {
        mode: 'setup',
        currency: customer.currency ?? 'usd',
        // Fully customizable with appearance API.
        appearance: {
            theme: 'stripe'
        },
    };

    return (
        <div>
            {paymentMethods != null && customer != null && (
                <div className="fixed-width">
                    <Stack spacing={2}>
                        <Typography variant="h5" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            <Trans text="billing_payment_methods" />
                            <IconButton aria-label="add" onClick={handleOpen}>
                                <AddIcon />
                            </IconButton>
                            <Modal
                                aria-labelledby="unstyled-modal-title"
                                aria-describedby="unstyled-modal-description"
                                open={open}
                                onClose={handleClose}
                                slots={{ backdrop: StyledBackdrop }}
                            >
                                <ModalContent sx={{ width: 800 }} textAlign="center">
                                    {/* Stripe */}
                                    <Elements stripe={stripePromise} options={options}>
                                        <BillingPaymentMethodsForm customer={customer}></BillingPaymentMethodsForm>
                                    </Elements>

                                </ModalContent>
                            </Modal>
                        </Typography>

                        <BillingPaymentMethodsItemsTable
                            customer={customer}
                            paymentMethods={paymentMethods}
                            onPaymentMethodsChange={onPaymentMethodsChange}
                            loadingPaymentMethods={loadingPaymentMethods}>
                        </BillingPaymentMethodsItemsTable>
                    </Stack>
                </div>
            )
            }
        </div>
    );
}

const Backdrop = forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
        <div
            className={clsx({ 'base-Backdrop-open': open }, className)}
            ref={ref}
            {...other}
        />
    );
});

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
    ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: right;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `,
);

export default BillingPaymentMethods;
