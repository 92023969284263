import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

//COMPONENTS
import { TransUpperCase, Trans } from "./Trans.js";

//UTILS
import { getCurrencyText } from "../utilities/Utils.js";

function BillingMessage({ invoices }) {

    let remainValue = -1
    if (invoices != null) {
        let filteredInvoices = invoices.filter((invoice) => { return invoice.status != "draft" && invoice.status != "paid" && invoice.status != "void" })
        remainValue = filteredInvoices.reduce((accumulator, currentValue) => accumulator + currentValue.amount_remaining, 0)
    }

    return (
        <div>
            {remainValue != -1 && (
                <div className="fixed-width">
                    <Stack spacing={2}>
                        <Typography variant="h5" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            <Trans text="blling_situation" />
                        </Typography>
                        {remainValue > 0 && (
                            <Alert variant="filled" severity="error">
                                <AlertTitle><Trans text="warning" /></AlertTitle>
                                <Trans text="billing_warning" /> :
                                <p></p>
                                <Typography variant="h4" gutterBottom>
                                    {getCurrencyText(remainValue / 100, invoices[0].currency)}
                                </Typography>
                            </Alert>
                        ) || (
                                <Alert variant="filled" severity="success">
                                    <AlertTitle><Trans text="success" /></AlertTitle>
                                    <Trans text="billing_success" />
                                </Alert>
                            )}
                    </Stack>
                </div>
            )
            }
        </div>
    );
}

export default BillingMessage;
