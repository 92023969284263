import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';

import { IconButton, Button } from "@mui/material";

import { styled } from "@mui/material/styles";

import i18n from "../locale/i18n.js";
import { useState, useEffect } from "react";

import backgroundImage from "../assets/background.png"; // Ensure the image is in ../assets/
import logoImage from "../assets/logo_eqsc.png"; // Logo image
import frenchFlag from "../assets/flag-fr.png"; // French flag
import britishFlag from "../assets/flag-uk.png"; // British flag
import arioneoLogo from "../assets/arioneo.png"; // new background logo

import { Navigate } from "react-router-dom";


//COMPONENTS
import { TransUpperCase, Trans } from "./Trans.js";

const defaultTheme = createTheme();

const Background = styled("div")({
    position: "fixed",
    width: "100%",
    minHeight: "100vh",
    // Use individual properties to handle multiple backgrounds more reliably:
    backgroundImage: `url(${backgroundImage})`,
    // The first is for the main background, the second for the logo
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    zIndex: -1, // Ensure it stays behind everything
});

const WatermarkLogo = styled("img")({
    position: "fixed",
    bottom: "20px",
    right: "20px",
    width: "120px",
    height: "auto",
    opacity: 0.8,
    zIndex: 0,
});

const TransparentNavbar = styled(AppBar)({
    background: "transparent", // Fully transparent
    boxShadow: "none", // Removes shadow
    color: "white", // Ensures visibility on different backgrounds
});

const HomeButton = styled(Button)({
    backgroundColor: "#B2A494", // Custom background color
    color: "white", // White text
    textTransform: "none", // Keeps text in normal case (not uppercase)
    padding: "6px 16px",
    fontSize: "1.2rem", // Increased font size
    fontFamily: "Lato, sans-serif", // Apply Lato font
    fontWeight: 400, // Regular
});

const Logo = styled("img")({
    height: "86px", // Adjust based on your design
    objectFit: "contain",
    marginTop: "24px", // Adds spacing above the logo
    marginBottom: "24px", // Adds spacing below the logo
});

const ContactButton = styled(Button)({
    color: "white",
    textTransform: "none",
    fontSize: "1.2rem", // Increased font size
    fontFamily: "Lato, sans-serif", // Apply Lato font
    fontWeight: 400, // Regular
});

const FlagButton = styled(IconButton)({
    padding: "5px",
    margin: "0 5px", // Small spacing between buttons
    "& img": {
        height: "24px", // Adjust flag size
        width: "36px",
        objectFit: "cover",
    },
});

const CenterContainer = styled("div")({
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
});

const CenteredGrid = styled(Grid)({
    height: "calc(100vh - 48px)", // Subtract the AppBar (Toolbar) height
    marginTop: "0px",            // Push content below the fixed AppBar
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "5%",
    paddingRight: "5%",
});

// Left Box with Text
const InfoBox = styled(Box)({
    background: "white",
    padding: "20px",
    borderRadius: "4px",
    boxShadow: "12px 12px 20px rgba(0, 0, 0, 0.4)", // Shadow only at the bottom
    color: "black",
    fontFamily: "Montserrat, sans-serif", // Apply Montserrat to InfoBox
    fontSize: "1rem",
    lineHeight: "1.5",
    // Make the container flex-column so we can push PriceBox to the bottom.
    display: "flex",
    flexDirection: "column",
});

function EqscReturn() {

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');

        fetch(`/session-status?session_id=${sessionId}`)
            .then((res) => res.json())
            .then((data) => {
                setStatus(data.status);
                setCustomerEmail(data.customer_email);
            });
    }, []);

    if (status === 'open') {
        return (
            <Navigate to="/checkout" />
        )
    }

    if (status === 'complete') {
        return (
            <ThemeProvider theme={defaultTheme}>
                <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
                <CssBaseline />

                <>
                    <Background />
                    <WatermarkLogo src={arioneoLogo} alt="Arioneo watermark" />
                    <TransparentNavbar position="fixed">
                        <Toolbar>
                            {/* Left Home Button */}
                            <HomeButton><TransUpperCase text={"home"} /></HomeButton>

                            {/* Center Logo */}
                            <CenterContainer>
                                <Logo src={logoImage} alt="Logo" />
                            </CenterContainer>

                            {/* Right Button */}
                            <ContactButton><TransUpperCase text={"contact_us"} /></ContactButton>

                            {/* Flag Buttons */}
                            <FlagButton>
                                <img src={frenchFlag} alt="French Flag" onClick={() => changeLanguage("fr")} />
                            </FlagButton>
                            <FlagButton>
                                <img src={britishFlag} alt="British Flag" onClick={() => changeLanguage("en")} />
                            </FlagButton>
                        </Toolbar>
                    </TransparentNavbar>
                    <CenteredGrid container spacing={8}>
                        <Grid item xs={6} md={8}>
                            <InfoBox>
                                <Typography textAlign="center" variant="h3" fontWeight="bold" fontFamily="Montserrat, sans-serif" marginBottom={2}>
                                    <Trans text={"thanks_for_suscribing"} />
                                </Typography>
                                <section id="success">
                                    <p>
                                        <Trans text={"thanks_line_1"} /> {customerEmail}.
                                    </p>
                                    <p>
                                        <Trans text={"thanks_line_2"} />
                                    </p>
                                    <p>
                                        <Trans text={"thanks_line_3"} /> <a href="mailto:equiscience@arioneo.com" style={{ fontFamily: "Montserrat, sans-serif", fontSize: '1rem' }}>equiscience@arioneo.com</a>.
                                    </p>
                                </section>
                            </InfoBox>
                        </Grid>
                    </CenteredGrid>

                </>

            </ThemeProvider >

        )
    }

    return null;
}

export default EqscReturn;
