import i18n from "../locale/i18n";
import { useState, useEffect, useRef } from "react";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { PDFDocument } from 'pdf-lib';
//COMPONENTS
import UserForms from "./UserForms.js";
import PurchaseTerms from "./PurchaseTerms.js";
import PaymentMethode from "./PaymentMethode.js";
import QuoteTotal from "./QuoteTotal.js";
import Signature from "./Signature.js";
import Actions from "./Actions.js";
import { TransUpperCase, Trans } from "./Trans.js";
import PurchaseTermsViewer from "./PurchaseTermsViewer.js";

function FullForm({ deal, lineItems, company, expirationDate, salesOwner, pageRef }) {
  const [state, setState] = useState({
    equimetreLastname: { value: "", required: "true", errorMessage: null },
    equimetreFirstname: { value: "", required: "true", errorMessage: null },
    equimetreEmail: { value: "", required: "true", errorMessage: null },
    equimetreRole: { value: "", required: "false", errorMessage: null },

    deliveryLastname: { value: "", required: "true", errorMessage: null },
    deliveryFirstname: { value: "", required: "true", errorMessage: null },
    deliveryCompany: { value: "", required: "false", errorMessage: null },
    deliveryEmail: { value: "", required: "true", errorMessage: null },
    deliveryPhone: { value: "", required: "true", errorMessage: null },
    deliveryAddressLine1: { value: "", required: "true", errorMessage: null },
    deliveryAddressLine2: {
      value: "",
      required: "false",
      errorMessage: null,
    },
    deliveryAddressLine3: {
      value: "",
      required: "false",
      errorMessage: null,
    },
    deliveryZipcode: { value: "", required: "true", errorMessage: null },
    deliveryCity: { value: "", required: "true", errorMessage: null },
    deliveryCountry: { value: "", required: "true", errorMessage: null },
    deliveryStateCode: { value: "", required: "false", errorMessage: null },

    billingLastname: {
      value: "",
      required: "false",
      errorMessage: null,
      copyToDelivery: "deliveryLastname",
      copyToEquimetre: "equimetreLastname",
    },
    billingFirstname: {
      value: "",
      required: "false",
      errorMessage: null,
      copyToDelivery: "deliveryFirstname",
      copyToEquimetre: "equimetreFirstname",
    },
    billingCompany: {
      value: "",
      required: "false",
      errorMessage: null,
      copyToDelivery: "deliveryCompany",
    },
    billingEmail: {
      value: "",
      required: "true",
      errorMessage: null,
      copyToDelivery: "deliveryEmail",
      copyToEquimetre: "equimetreEmail",
    },
    billingPhone: {
      value: "",
      required: "false",
      errorMessage: null,
      copyToDelivery: "deliveryPhone",
    },
    billingAddressLine1: {
      value: "",
      required: "true",
      errorMessage: null,
      copyToDelivery: "deliveryAddressLine1",
    },
    billingAddressLine2: {
      value: "",
      required: "false",
      errorMessage: null,
      copyToDelivery: "deliveryAddressLine2",
    },
    billingAddressLine3: {
      value: "",
      required: "false",
      errorMessage: null,
      copyToDelivery: "deliveryAddressLine3",
    },
    billingZipcode: {
      value: "",
      required: "true",
      errorMessage: null,
      copyToDelivery: "deliveryZipcode",
    },
    billingCity: {
      value: "",
      required: "true",
      errorMessage: null,
      copyToDelivery: "deliveryCity",
    },
    billingCountry: {
      value: "",
      required: "true",
      errorMessage: null,
      copyToDelivery: "deliveryCountry",
    },
    billingStateCode: {
      value: "",
      required: "false",
      errorMessage: null,
      copyToDelivery: "deliveryStateCode",
    },
    billingTVA: {
      value: "",
      required: "false",
      errorMessage: null
    },

    paymentMethod: {
      value: "",
      required: "true",
      errorMessage: null
    },
    purchaseTermsAcceptance: {
      value: "",
      required: "true",
      errorMessage: null,
    },

    isLoading: {
      value: false,
      required: "false",
      errorMessage: null
    },
  });

  const [signature, setSignature] = useState({
    value: "",
    required: "true",
    errorMessage: null,
  })

  const [copyBillingToDelivery, setCopyBillingToDelivery] = useState(false);
  const [copyBillingToEquimetre, setCopyBillingToEquimetre] = useState(false);

  const [apiUrl, setApiUrl] = useState(i18n.t("quote_terms_page"));

  // Reference to the signature component function
  const signatureRef = useRef();

  // Callback function to receive result from the signature component
  const handleSignatureResult = (result) => {
    setSignature({
      value: result,
      required: "true",
      errorMessage: result == "" ? "signature needed" : null,
    });
  }

  useEffect(() => {
    if (signature.value.length == 0) {
      return
    }
    else if (signature.value.length > 0 && handleValidation()) {
      //Start spinner
      setState({
        ...state,
        isLoading: { value: true, required: "true", errorMessage: null },
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          companyId: company.hs_object_id,
          data: {
            adresse_ligne_1__facturation_: state.billingAddressLine1.value,
            adresse_ligne_1__livraison_: state.deliveryAddressLine1.value,
            adresse_ligne_2__facturation_: state.billingAddressLine2.value,
            adresse_ligne_2__livraison_: state.deliveryAddressLine2.value,
            adresse_ligne_3__facturation_: state.billingAddressLine3.value,
            adresse_ligne_3__livraison_: state.deliveryAddressLine3.value,
            code_postal__facturation_: state.billingZipcode.value,
            code_postal__livraison_: state.deliveryZipcode.value,
            e_mail_de_facturation: state.billingEmail.value,
            e_mail_de_suivi_de_livraison: state.deliveryEmail.value,
            email_compte_eqm: state.equimetreEmail.value,
            nom__facturation_: state.billingLastname.value,
            nom__livraison_: state.deliveryLastname.value,
            nom_de_societe__facturation_: state.billingCompany.value,
            nom_de_societe__livraison_: state.deliveryCompany.value,
            nom_du_compte_equimetre: state.equimetreLastname.value,
            numero_de_tva_intra_communautaire__facturation_:
              state.billingTVA.value,
            pays__facturation_: state.billingCountry.value,
            pays__livraison_: state.deliveryCountry.value,
            prenom__facturation_: state.billingFirstname.value,
            prenom__livraison_: state.deliveryFirstname.value,
            prenom_du_compte_eqm: state.equimetreFirstname.value,
            role_du_contact_compte_eqm: state.equimetreRole.value,
            telephone__facturation_: state.billingPhone.value,
            telephone__livraison_: state.deliveryPhone.value,
            ville__facturation_: state.billingCity.value,
            ville__livraison_: state.deliveryCity.value,
            state_code_us__facturation_: state.billingStateCode.value,
            state_code_us__livraison_: state.deliveryStateCode.value,
            signature: signature.value
          },
        }),
      };

      const dealRequestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          dealId: deal.hs_object_id,
          data: {
            paiement_type: state.paymentMethod.value,
          },
        }),
      };

      fetch("/updateCompany", requestOptions)
        .then((response) => response.json())
        .then((json) => {
          const data = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              data: lineItems,
              dealId: deal.hs_object_id,
            }),
          };

          fetch("/updateDealInformationsFromLineItems", data);

          fetch("/updatePaymentMethod", dealRequestOptions)
            .then((response) => response.json())
            .then((json) => {
              var link = deal.dealtype == "Equiscience" ? "/getPaymentLinkV2Equiscience?dealId=" : "/getPaymentLinkV2?dealId="
              fetch(link +
                deal.hs_object_id +
                "&salesCegidCode=" +
                salesOwner.cegidCode
              )
                .then((response) => response.json())
                .then((json) => {
                  handleSendEmail().then(() => {
                    //Evaluate response
                    if (json.paymentLink && json.paymentLink.length > 0) {
                      if (state.paymentMethod.value !== "Bank Transfer") {
                        window.location.href = json.paymentLink;
                      } else {
                        window.location.reload(true);
                      }
                    } else if (json.error) {
                      alert(json.error);
                    } else {
                      alert(json);
                    }

                    //Stop spinner
                    setState({
                      ...state,
                      isLoading: {
                        value: false,
                        required: "true",
                        errorMessage: null,
                      },
                    });
                  }

                  )

                })
                .catch((err) => {
                  alert(err);
                });
            })
            .catch((err) => {
              alert(err);
            });
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      alert(i18n.t("user_forms_complete_all_required_fields"));
    }
  }, [signature]);

  useEffect(() => {

    setState({
      equimetreLastname: {
        value: company.nom_du_compte_equimetre ?? "",
        required: "true",
        errorMessage: null,
      },
      equimetreFirstname: {
        value: company.prenom_du_compte_eqm ?? "",
        required: "true",
        errorMessage: null,
      },
      equimetreEmail: {
        value: company.email_compte_eqm ?? "",
        required: "true",
        errorMessage: null,
      },
      equimetreRole: {
        value: company.role_du_contact_compte_eqm ?? "",
        required: "false",
        errorMessage: null,
      },

      deliveryLastname: {
        value: company.nom__livraison_ ?? "",
        required: "true",
        errorMessage: null,
      },
      deliveryFirstname: {
        value: company.prenom__livraison_ ?? "",
        required: "true",
        errorMessage: null,
      },
      deliveryCompany: {
        value: company.nom_de_societe__livraison_ ?? "",
        required: "false",
        errorMessage: null,
      },
      deliveryEmail: {
        value: company.e_mail_de_suivi_de_livraison ?? "",
        required: "true",
        errorMessage: null,
      },
      deliveryPhone: {
        value: company.telephone__livraison_ ?? "",
        required: "true",
        errorMessage: null,
      },
      deliveryAddressLine1: {
        value: company.adresse_ligne_1__livraison_ ?? "",
        required: "true",
        errorMessage: null,
      },
      deliveryAddressLine2: {
        value: company.adresse_ligne_2__livraison_ ?? "",
        required: "false",
        errorMessage: null,
      },
      deliveryAddressLine3: {
        value: company.adresse_ligne_3__livraison_ ?? "",
        required: "false",
        errorMessage: null,
      },
      deliveryZipcode: {
        value: company.code_postal__livraison_ ?? "",
        required: "true",
        errorMessage: null,
      },
      deliveryCity: {
        value: company.ville__livraison_ ?? "",
        required: "true",
        errorMessage: null,
      },
      deliveryCountry: {
        value: company.pays__livraison_ ?? "",
        required: "true",
        errorMessage: null,
      },
      deliveryStateCode: {
        value: company.state_code_us__livraison_ ?? "",
        required: "false",
        errorMessage: null,
      },

      billingLastname: {
        value: company.nom__facturation_ ?? "",
        required: "false",
        errorMessage: null,
        copyToDelivery: "deliveryLastname",
        copyToEquimetre: "equimetreLastname",
      },
      billingFirstname: {
        value: company.prenom__facturation_ ?? "",
        required: "false",
        errorMessage: null,
        copyToDelivery: "deliveryFirstname",
        copyToEquimetre: "equimetreFirstname",
      },
      billingCompany: {
        value: company.nom_de_societe__facturation_ ?? "",
        required: "false",
        errorMessage: null,
        copyToDelivery: "deliveryCompany",
      },
      billingEmail: {
        value: company.e_mail_de_facturation ?? "",
        required: "true",
        errorMessage: null,
        copyToDelivery: "deliveryEmail",
        copyToEquimetre: "equimetreEmail",
      },
      billingPhone: {
        value: company.telephone__facturation_ ?? "",
        required: "true",
        errorMessage: null,
        copyToDelivery: "deliveryPhone",
      },
      billingAddressLine1: {
        value: company.adresse_ligne_1__facturation_ ?? "",
        required: "true",
        errorMessage: null,
        copyToDelivery: "deliveryAddressLine1",
      },
      billingAddressLine2: {
        value: company.adresse_ligne_2__facturation_ ?? "",
        required: "false",
        errorMessage: null,
        copyToDelivery: "deliveryAddressLine2",
      },
      billingAddressLine3: {
        value: company.adresse_ligne_3__facturation_ ?? "",
        required: "false",
        errorMessage: null,
        copyToDelivery: "deliveryAddressLine3",
      },
      billingZipcode: {
        value: company.code_postal__facturation_ ?? "",
        required: "true",
        errorMessage: null,
        copyToDelivery: "deliveryZipcode",
      },
      billingCity: {
        value: company.ville__facturation_ ?? "",
        required: "true",
        errorMessage: null,
        copyToDelivery: "deliveryCity",
      },
      billingCountry: {
        value: company.pays__facturation_ ?? "",
        required: "true",
        errorMessage: null,
        copyToDelivery: "deliveryCountry",
      },
      billingStateCode: {
        value: company.state_code_us__facturation_ ?? "",
        required: "false",
        errorMessage: null,
        copyToDelivery: "deliveryStateCode",
      },
      billingTVA: {
        value: company.numero_de_tva_intra_communautaire__facturation_ ?? "",
        required: "false",
        errorMessage: null,
      },

      paymentMethod: { value: "", required: "true", errorMessage: null },
      purchaseTermsAcceptance: {
        value: "",
        required: "true",
        errorMessage: null,
      },

      isLoading: { value: false, required: "false", errorMessage: null },
    });
    setCopyBillingToDelivery(checkIfCopyBillingToDelivery(company));
    setCopyBillingToEquimetre(checkIfCopyBillingToEquimetre(company));
    if (deal.dealtype == "Equiscience") {
      setCopyBillingToDelivery(true);
      setCopyBillingToEquimetre(true);
    }
  }, [company]);

  function checkIfCopyBillingToDelivery(company) {
    if (
      company.prenom__facturation_ === company.prenom__livraison_ &&
      company.nom__facturation_ === company.nom__livraison_ &&
      company.nom_de_societe__facturation_ ===
      company.nom_de_societe__livraison_ &&
      company.e_mail_de_facturation === company.e_mail_de_suivi_de_livraison &&
      company.telephone__facturation_ === company.telephone__livraison_ &&
      company.adresse_ligne_1__facturation_ ===
      company.adresse_ligne_1__livraison_ &&
      company.adresse_ligne_2__facturation_ ===
      company.adresse_ligne_2__livraison_ &&
      company.adresse_ligne_3__facturation_ ===
      company.adresse_ligne_3__livraison_ &&
      company.code_postal__facturation_ === company.code_postal__livraison_ &&
      company.ville__facturation_ === company.ville__livraison_ &&
      company.pays__facturation_ === company.pays__livraison_ &&
      company.state_code_us__facturation_ === company.state_code_us__livraison_
    ) {
      return true;
    }
    return false;
  }

  function checkIfCopyBillingToEquimetre(company) {
    if (
      company.prenom__facturation_ === company.prenom_du_compte_eqm &&
      company.nom__facturation_ === company.nom_du_compte_equimetre &&
      company.e_mail_de_facturation === company.email_compte_eqm
    ) {
      return true;
    }
    return false;
  }

  function onChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    //Update value onChange
    let item = state[name];
    item.value = value;
    setState({ ...state, [name]: item });

    //Copy only this value if copyBillingToDelivery
    if (copyBillingToDelivery === true && item.copyToDelivery) {
      let itemCopy = state[item.copyToDelivery];
      itemCopy.value = value;
      setState({ ...state, [item.copyToDelivery]: itemCopy });
    }
    //Copy only this value if copyBillingToEquimetre
    if (copyBillingToEquimetre === true && item.copyToEquimetre) {
      let itemCopy = state[item.copyToEquimetre];
      itemCopy.value = value;
      setState({ ...state, [item.copyToEquimetre]: itemCopy });
    }
  }

  function onCheckBoxChange(e) {
    const name = e.target.name;
    const value = e.target.checked;

    if (name === "copyBillingToDelivery") {
      setCopyBillingToDelivery(value);
      //Copy all the billing informations to the shipping informations
      if (value === true) {
        let arr = Object.keys(state).filter((key) => state[key].copyToDelivery);
        for (const key of arr) {
          let item = state[key];
          let itemCopy = state[item.copyToDelivery];
          itemCopy.value = item.value;
          setState({ ...state, [item.copyToDelivery]: itemCopy });
        }
      } else {
        let arr = Object.keys(state).filter((key) => state[key].copyToDelivery);
        for (const key of arr) {
          let item = state[key];
          let itemCopy = state[item.copyToDelivery];
          itemCopy.value = "";
          setState({ ...state, [item.copyToDelivery]: itemCopy });
        }
      }
    } else if (name === "copyBillingToEquimetre") {
      setCopyBillingToEquimetre(value);
      //Copy all the billing informations to the shipping informations
      if (value === true) {
        let arr = Object.keys(state).filter(
          (key) => state[key].copyToEquimetre
        );
        for (const key of arr) {
          let item = state[key];
          let itemCopy = state[item.copyToEquimetre];
          itemCopy.value = item.value;
          setState({ ...state, [item.copyToEquimetre]: itemCopy });
        }
      } else {
        let arr = Object.keys(state).filter(
          (key) => state[key].copyToEquimetre
        );
        for (const key of arr) {
          let item = state[key];
          let itemCopy = state[item.copyToEquimetre];
          itemCopy.value = "";
          setState({ ...state, [item.copyToEquimetre]: itemCopy });
        }
      }
    } else if (name === "purchaseTermsAcceptance") {
      let item = state[name];
      item.value = value;
      setState({ ...state, [name]: item });
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (signatureRef.current) {
      signatureRef.current.executeFunction();
    }

  }

  function handleValidation() {

    let formIsValid = true;

    let arr = Object.keys(state).filter(
      (key) => state[key].required === "true"
    );

    for (const key of arr) {
      let item = state[key];
      if (item.value.length <= 0) {
        item.errorMessage = <Trans text="user_forms_requested_field"></Trans>;
        setState({ ...state, [item.name]: item });
        if (deal.dealtype != "Equiscience") {
          formIsValid = false;
        }
      } else {
        item.errorMessage = null;
        setState({ ...state, [item.name]: item });
      }
    }

    //Check purchaseTermsAcceptance
    if (state.purchaseTermsAcceptance.value !== true) {
      let purchaseTermsAcceptance = state.purchaseTermsAcceptance;
      purchaseTermsAcceptance.errorMessage = (
        <Trans text="user_forms_requested_field"></Trans>
      );
      setState({ ...state, purchaseTermsAcceptance: purchaseTermsAcceptance });
      formIsValid = false;
    }

    //Check State Code if Country == USA
    if (
      state.billingCountry === "United States" &&
      state.billingStateCode.value.length <= 0
    ) {
      let billingStateCode = state.billingStateCode;
      billingStateCode.errorMessage = (
        <Trans text="user_forms_requested_field"></Trans>
      );
      setState({ ...state, billingStateCode: billingStateCode });
      formIsValid = false;
    }
    if (
      state.deliveryCountry === "United States" &&
      state.deliveryStateCode.value.length <= 0
    ) {
      let deliveryStateCode = state.deliveryStateCode;
      deliveryStateCode.errorMessage = (
        <Trans text="user_forms_requested_field"></Trans>
      );
      setState({ ...state, deliveryStateCode: deliveryStateCode });
      formIsValid = false;
    }

    //Check the quality of the TVA number
    let billingTVA = state.billingTVA;
    if (billingTVA.value === null || billingTVA.value === "") {
      //Pas de TVA, pas de message d'erreur
      billingTVA.errorMessage = null;
      setState({ ...state, billingTVA: billingTVA });
    } else {
      //On regarde la taille du numéro
      if (billingTVA.value.length > 2) {
        //On regarde si on a bien 2 lettres et des chiffres
        const prefix = billingTVA.value.substring(0, 2).toUpperCase();
        const result =
          prefix.match(/[A-Z]/g) && prefix.match(/[A-Z]/g).length === 2;
        if (result) {
          billingTVA.errorMessage = null;
          setState({ ...state, billingTVA: billingTVA });
        } else {
          billingTVA.errorMessage = (
            <Trans text="user_forms_wrong_tva_number"></Trans>
          );
          setState({ ...state, billingTVA: billingTVA });
          formIsValid = false;
        }
      } else {
        billingTVA.errorMessage = (
          <Trans text="user_forms_wrong_tva_number"></Trans>
        );
        setState({ ...state, billingTVA: billingTVA });
        formIsValid = false;
      }
    }

    return formIsValid;
  }

  function handleSaveForm() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        companyId: company.hs_object_id,
        data: {
          adresse_ligne_1__facturation_: state.billingAddressLine1.value,
          adresse_ligne_1__livraison_: state.deliveryAddressLine1.value,
          adresse_ligne_2__facturation_: state.billingAddressLine2.value,
          adresse_ligne_2__livraison_: state.deliveryAddressLine2.value,
          adresse_ligne_3__facturation_: state.billingAddressLine3.value,
          adresse_ligne_3__livraison_: state.deliveryAddressLine3.value,
          code_postal__facturation_: state.billingZipcode.value,
          code_postal__livraison_: state.deliveryZipcode.value,
          e_mail_de_facturation: state.billingEmail.value,
          e_mail_de_suivi_de_livraison: state.deliveryEmail.value,
          email_compte_eqm: state.equimetreEmail.value,
          nom__facturation_: state.billingLastname.value,
          nom__livraison_: state.deliveryLastname.value,
          nom_de_societe__facturation_: state.billingCompany.value,
          nom_de_societe__livraison_: state.deliveryCompany.value,
          nom_du_compte_equimetre: state.equimetreLastname.value,
          numero_de_tva_intra_communautaire__facturation_:
            state.billingTVA.value,
          pays__facturation_: state.billingCountry.value,
          pays__livraison_: state.deliveryCountry.value,
          prenom__facturation_: state.billingFirstname.value,
          prenom__livraison_: state.deliveryFirstname.value,
          prenom_du_compte_eqm: state.equimetreFirstname.value,
          role_du_contact_compte_eqm: state.equimetreRole.value,
          telephone__facturation_: state.billingPhone.value,
          telephone__livraison_: state.deliveryPhone.value,
          ville__facturation_: state.billingCity.value,
          ville__livraison_: state.deliveryCity.value,
          state_code_us__facturation_: state.billingStateCode.value,
          state_code_us__livraison_: state.deliveryStateCode.value,
        },
      }),
    };

    fetch("/updateCompany", requestOptions)
      .then((response) => response.json())
      .then((json) => {
        alert(i18n.t("actions_save_results"));
      });
  }

  //PDF
  const generatePTPDF = async () => {
    const existingPdfBytes = await fetch(apiUrl).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    const imageBytes = signature.value.split(',')[1]; // Remove the data URL part
    const pngImage = await pdfDoc.embedPng(Uint8Array.from(atob(imageBytes), c => c.charCodeAt(0)));

    const page = pdfDoc.getPages()[0];
    const width = page.getWidth()

    // Add signature
    var signatureWidth = width / 10; // Image width in units
    var signatureHeight = width / 20; // Image height in units
    var x = width - signatureWidth - 5; // 10 units margin from the right
    var y = 5; // 10 units margin from the bottom

    const pages = pdfDoc.getPages();
    pages.forEach(page => {
      // Add the image to the page
      page.drawImage(pngImage, {
        x: x,
        y: y, // Position it on the page
        width: signatureWidth,
        height: signatureHeight,
      });
    })

    // Serialize the PDFDocument to bytes
    const pdfBytes = await pdfDoc.save();

    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

    return pdfBlob;

  };

  const generatePOPDF = async () => {
    const input = pageRef; // Target the DOM element

    const canvas = await html2canvas(input, { useCORS: true, allowTaint: true, scale: 2 }) // capture the component as a canvas

    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4', true);
    const imgWidth = 210;
    const pageHeight = 297;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;

    // Add first page
    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    // Add signature
    var signatureWidth = 30; // Image width in units
    var signatureHeight = 15; // Image height in units
    var x = imgWidth - signatureWidth - 5; // 10 units margin from the right
    var y = pageHeight - signatureHeight - 5; // 10 units margin from the bottom
    pdf.addImage(signature.value, 'PNG', x, y, signatureWidth, signatureHeight);

    // Add more pages if needed
    while (heightLeft > 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    // Save as a Blob or Base64
    const pdfBlob = pdf.output('blob');  // Create a Blob for sending to the backend

    return pdfBlob;
  };

  const convertToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);  // Convert blob to base64
    });
  };

  async function handleSendEmail() {
    const pdfBlobPT = await generatePTPDF();
    const base64PDFPT = await convertToBase64(pdfBlobPT);

    const pdfBlobPO = await generatePOPDF();
    const base64PDFPO = await convertToBase64(pdfBlobPO);

    // Send the base64 PDF to your backend
    await sendEmailWithPdf(base64PDFPO.split(",")[1], base64PDFPT.split(",")[1]);
  };

  const sendEmailWithPdf = async (base64PDFPO, base64PDFPT) => {

    try {
      await fetch('/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          pdfpo: base64PDFPO, // Include the base64-encoded PDF in the request body
          pdfpt: base64PDFPT, // Include the base64-encoded PDF in the request body
          recipient: state.billingEmail.value,
        }),
      });
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  var buttonClassname = "button-checkout-equimetre";

  switch (deal.dealtype) {
    case "EQUIMETRE FLAT":
      buttonClassname = "button-checkout-equimetre";
      break;
    case "EQUIMETRE TROT":
      buttonClassname = "button-checkout-equimetre";
      break;
    case "EQUIMETRE VET":
      buttonClassname = "button-checkout-equimetre-vet";
      break;
    case "EQUISYM":
      buttonClassname = "button-checkout-equisym";
      break;
    case "Equiscience":
      buttonClassname = "button-checkout-equiscience";
      break;
    default:
      break;
  }

  return (
    <form onSubmit={handleSubmit}>
      <UserForms
        deal={deal}
        values={state}
        onChange={(e) => onChange(e)}
        copyBillingToDelivery={copyBillingToDelivery}
        copyBillingToEquimetre={copyBillingToEquimetre}
        onCheckBoxChange={(e) => onCheckBoxChange(e)}
      ></UserForms>
      <p>&nbsp;</p>
      <div className="fixed-width">
        <div className="text-center m-top-2 m-bottom-10">
          <button
            onClick={handleSaveForm}
            type="button"
            data-selenium-test="quotes-purchase-btn"
            data-loading="false"
            data-loading-state="idle"
            aria-disabled="false"
            className={
              "uiButton private-button private-button--default private-loading-button custom-color-button private-button--active private-button--non-link " +
              buttonClassname
            }
            data-button-use="tertiary"
            aria-pressed="true"
          >
            <span className="private-loading-button__content private-button--internal-spacing">
              <div>
                <Trans text="actions_save"></Trans>
              </div>
            </span>
          </button>
        </div>
      </div>
      <PaymentMethode
        values={state}
        lineItems={lineItems}
        onChange={(e) => onChange(e)}
        errorMessage={state.paymentMethod.errorMessage}
      ></PaymentMethode>
      <PurchaseTerms
        dealtype={deal.dealtype}
      ></PurchaseTerms>
      <div className="fixed-width">
        <QuoteTotal
          values={state}
          deal={deal}
          lineItems={lineItems}
          company={company}
          expirationDate={expirationDate}
        ></QuoteTotal>
      </div>
      <PurchaseTermsViewer
        //apiUrl={i18n.t("quote_terms_page")}
        dealtype={deal.dealtype}
        onCheckBoxChange={(e) => onCheckBoxChange(e)}
        errorMessage={state.purchaseTermsAcceptance.errorMessage}>
      </PurchaseTermsViewer>
      <Signature dealtype={deal.dealtype} ref={signatureRef} onSignature={handleSignatureResult} errorMessage={signature.errorMessage}></Signature>
      <Actions
        dealtype={deal.dealtype}
        isLoading={state.isLoading.value}
      ></Actions>
    </form>
  );
}

export default FullForm;
