import { useCheckout } from '@stripe/react-stripe-js';
import { useState } from "react";
import { Box, Button } from '@mui/material';
import { styled } from "@mui/material/styles";

import {
    FormControl,
    MenuItem,
    Select
} from '@mui/material';

const CenteredBox = styled(Box)({
    alignSelf: "center",  // centers horizontally
});

const CodeButton = styled(Button)({
    backgroundColor: "#B2A494",
    fontWeight: 700, // Bold
})

const CurrencySelection = ({ currency, onChangeCurrency }) => {

    const handleChangeCurrency = (event) => {
        onChangeCurrency(event.target.value);
    };

    return (
        <FormControl variant="outlined"
            sx={{ // Target the outlined input's notched outline
                '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                },
                '& .MuiSelect-select': {
                    color: 'white',
                    fontSize: '2rem', // or 20px or whatever size you prefer
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                    padding: 0,
                },
                // Style the icon (arrow) if you want it white
                '& .MuiSvgIcon-root': {
                    color: 'white',
                },
                // Target the root of the outlined input
                '& .MuiOutlinedInput-root': {
                    // Set a fixed height for the input
                    height: 48, // example: 48px
                    display: 'flex',
                    alignItems: 'center', // center content vertically
                    padding: 0,           // remove default spacing
                },
                // Remove FormControl padding
                p: 0,
            }}  // or any pixel/percentage value you want
        >
            <Select
                labelId="currency-select-label"
                id="currency-select"
                value={currency}
                label="Currency"
                onChange={handleChangeCurrency}
            >
                <MenuItem value="USD">$</MenuItem>
                <MenuItem value="EUR">€</MenuItem>
                <MenuItem value="GBP">£</MenuItem>
                <MenuItem value="AUD">A$</MenuItem>
                {/* Add or remove currencies as needed */}
            </Select>
        </FormControl >
    );
};

export default CurrencySelection;