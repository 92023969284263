import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

//COMPONENTS
import { TransUpperCase, Trans } from "./Trans.js";

//UTILS
import { getCurrencyText } from "../utilities/Utils.js";

function BillingInvoicesItemsTable({ invoices, creditNotes, cegidDocs, paymentMethods, customer }) {

    const downloadInvoice = (filename, filepath) => {
        fetch('/download?filename=' + filename + '&filepath=' + filepath, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    filename,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    function InvoiceChip({ invoice }) {
        var chipColor = ""
        var chipLabel = ""

        switch (invoice.status) {
            case "paid":
                chipColor = "success"
                chipLabel = <Trans text="paid" />
                break;
            case "past_due":
                chipColor = "error"
                chipLabel = <Trans text="past_due" />
                break;
            case "open":
                if (invoice.amount_remaining > 0) {
                    chipColor = "error"
                    chipLabel = <Trans text="past_due" />
                    break;
                }
                else {
                    chipColor = "primary"
                    chipLabel = <Trans text="open" />
                    break;
                }

            default:
                chipColor = "default"
                chipLabel = ""
                break;
        }
        return (
            <Chip label={chipLabel} color={chipColor} size="small" />
        );
    }

    function getSum(total, num) {
        return total + num;
    }

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        let totalInvoiced = row.total
        let totalCredited = row.creditNotes.map((creditNote) => { return creditNote.total }).reduce(getSum, 0)
        let total = totalInvoiced - totalCredited

        let defaultPaymentMethod = paymentMethods.filter((method) => { return method.id == customer.invoice_settings.default_payment_method })
        var digit = ""
        if (defaultPaymentMethod.length > 0) {
            switch (defaultPaymentMethod[0].type) {
                case "card":
                    digit = defaultPaymentMethod[0].card.last4
                    break;
                case "sepa_debit":
                    digit = defaultPaymentMethod[0].sepa_debit.last4
                    break;
                case "link":
                    digit = ""
                    break;
                default:
                    break;
            }
        }

        return (
            <React.Fragment>
                <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >

                    <TableCell style={{ width: '50px' }}>
                        {row.creditNotes.length > 0 && (
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}
                            >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        )}
                    </TableCell>
                    <TableCell align="center">
                        {total == row.total && getCurrencyText(total / 100, row.currency)}
                        {total != row.total && (
                            <div>
                                <Typography variant="body2" sx={{ textDecoration: "line-through" }}>
                                    {getCurrencyText(row.total / 100, row.currency)}
                                </Typography>
                                {getCurrencyText(total / 100, row.currency)}
                            </div>

                        )}
                        {/* {getCurrencyText(total / 100, row.currency)} */}
                    </TableCell>
                    <TableCell align="center">{(new Date(row.effective_at * 1000)).toLocaleDateString()}</TableCell>
                    <TableCell align="center">
                        <InvoiceChip invoice={row}></InvoiceChip>
                        {row.status != 'paid' && row.amount_remaining > 0 && (
                            <div>
                                <div>
                                    <Link href={row.hosted_invoice_url}><Trans text="billing_payment_page" /></Link>
                                </div>
                                {/* <div>
                                    <Link href="#">{"Pay with ••• " + digit}</Link>
                                </div> */}
                            </div>
                        )}
                    </TableCell>
                    <TableCell align="center">{row.number}</TableCell>
                    <TableCell align="center">
                        <Stack spacing={1}>
                            {row.cegidDocs.map((doc) => (
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                                    {doc.filename}
                                    <IconButton aria-label="delete">
                                        <DownloadIcon onClick={() => downloadInvoice(doc.filename, doc.filepath)} />
                                    </IconButton>
                                </Stack>
                            )
                            )}
                        </Stack>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit style={{ padding: 0 }}>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="body1" gutterBottom component="div">
                                    <Trans text="billing_credit_notes_applied" /> :
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableBody>
                                        {row.creditNotes.map((creditNote) => (
                                            <TableRow
                                                key={creditNote.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell style={{ width: '50px' }}>

                                                </TableCell>
                                                <TableCell align="center">
                                                    {getCurrencyText(creditNote.total / 100, creditNote.currency)}
                                                </TableCell>
                                                <TableCell align="center">{(new Date(creditNote.effective_at * 1000)).toLocaleDateString()}</TableCell>
                                                <TableCell align="center"></TableCell>
                                                <TableCell align="center">{creditNote.number}</TableCell>
                                                <TableCell align="center">
                                                    <Stack spacing={1} >
                                                        {creditNote.cegidDocs.map((doc) => (
                                                            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                                                                {doc.filename}
                                                                <IconButton aria-label="delete">
                                                                    <DownloadIcon onClick={() => downloadInvoice(doc.filename, doc.filepath)} />
                                                                </IconButton>
                                                            </Stack>
                                                        )
                                                        )}
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment >
        );
    }

    let creditNotesArray = creditNotes
        .map((item) => {
            return ({
                ...item, cegidDocs: cegidDocs.filter((doc) => doc.ref == item.number)
            })
        })
    let invoicesArray = invoices
        .map((item) => {
            return ({
                ...item, creditNotes: creditNotesArray.filter((creditNote) => creditNote.invoice == item.id), cegidDocs: cegidDocs.filter((doc) => doc.ref == item.number)
            })
        })

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '50px' }} />
                        {["billing_invoices_amount", "billing_invoices_date", "billing_invoices_status", "billing_invoices_sales_order", "billing_invoices_invoice"].map((title) => (
                            <TableCell align="center">
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                    <Trans text={title}></Trans>
                                </Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {invoicesArray.map((row) => (
                        <Row key={row.id} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default BillingInvoicesItemsTable;
