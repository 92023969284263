import i18n from "../locale/i18n";
//CSS
import "../styles/App.css";
//COMPONENTS
import { TransUpperCase, Trans } from "./Trans.js";
import Topbar from "./Topbar.js";
import Footer from "./Footer.js";
import PurchaseTermsText from "./PurchaseTermsText.js";
import InvoicesList from "./InvoicesList.js";
import Quote from "./Quote";

import { useState, useEffect } from "react";

function Following({ deal, lineItems, company, salesOwner }) {
  const [state, setState] = useState({
    customer: null
  });

  async function callAPI() {
    const responseCustomer = await fetch("/getCustomerFromEmail?email=" + company.e_mail_de_facturation);
    const jsonCustomer = await responseCustomer.json();

    console.log(jsonCustomer.customer)

    setState({
      customer: jsonCustomer.customer,
    });
  }

  useEffect(() => {
    callAPI();
  }, [company]);

  function onHandleClick() {
    var link = deal.delivery_tracking_link;
    window.location.href = link;
  }

  function onHandleBillingClick() {
    var link = "https://quote.equimetre.com/billing/" + state.customer.id;
    window.location.href = link;
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  var quoteTermsClassname = "rounded-border quote-terms";
  var quoteTermsLink = "https://hubs.ly/H0n42zC0";

  switch (deal.dealtype) {
    case "EQUIMETRE FLAT":
      quoteTermsClassname = "rounded-border quote-terms quote-terms-equimetre";
      break;
    case "EQUIMETRE TROT":
      quoteTermsClassname = "rounded-border quote-terms quote-terms-equimetre";
      break;
    case "EQUIMETRE VET":
      quoteTermsClassname =
        "rounded-border quote-terms quote-terms-equimetre-vet";
      break;
    case "EQUISYM":
      quoteTermsClassname = "rounded-border quote-terms quote-terms-equisym";
      quoteTermsLink = "https://hubs.ly/Q01l4qmf0"
      break;
    case "Equiscience":
      quoteTermsClassname = "rounded-border quote-terms quote-terms-equiscience";
      quoteTermsLink = "https://quote.equimetre.com/202405_ARIONEO_FORMATION_CGV_ENG.pdf"
      break;
    default:
      break;
  }

  //Else -> Forms
  return (
    <div className="page">
      <div className="quote-page-container valid">
        <div className="original-quote-template">
          <div className="brofile brofile-sticky">
            <div
              wrap="wrap"
              direction="row"
              className="UIFlex__StyledFlex-gi5hfq-0 jAMQbh private-flex Brofile__ResponsiveFlex-sc-1vi4x4x-0 fFinRi brofile-content fixed-width"
            >
              <img
                alt=""
                className="private-image company-logo m-y-auto img-responsive private-image--responsive"
                src="https://cdn2.hubspot.net/hubfs/4217512/Noir-fd%20transparent-signature.png"
              ></img>
              <div className="text-center m-y-auto">
                <ul className="uiList private-list--inline quote-actions private-list--unstyled">
                  <li className="uiListItem private-list__item">
                    <button className="flag-button">
                      <img
                        src="https://img.icons8.com/color/96/000000/great-britain-circular.png"
                        alt="GB"
                        width="48"
                        height="48"
                        onClick={() => changeLanguage("en")}
                      />
                    </button>
                  </li>
                  <li className="uiListItem private-list__item">
                    <button className="flag-button">
                      <img
                        src="https://img.icons8.com/color/96/000000/france-circular.png"
                        width="48"
                        height="48"
                        alt="FR"
                        onClick={() => changeLanguage("fr")}
                      />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Topbar
            deal={deal}
            company={company}
            salesOwner={salesOwner}
          ></Topbar>
          <p> </p>
          <div className="quote-terms-container fixed-width">
            <h2 className="m-bottom-12 m-top-12 text-center">
              <Trans text="following_subtitle"></Trans>
            </h2>
            <div className="hs-form__row">
              <div className="hs-form__group">
                <div className="hs-form__field-row">
                  <div className="hs-form__field-row__column">
                    <div className="rounded-border quote-terms">
                      <h4 className="m-bottom-8 text-center">
                        <Trans text="following_order_title"></Trans>
                      </h4>
                      <div className="m-bottom-0 is--heading-7 quote-rich-text-body link-obsidian-container">
                        {deal.delivery_tracking_link && (
                          <div className="text-center">
                            <p>
                              <Trans text="following_order_subtitle_sent"></Trans>
                            </p>
                            <p></p>
                            <p></p>
                            <p></p>
                            <button
                              data-selenium-test="quotes-purchase-btn"
                              data-loading="false"
                              data-loading-state="idle"
                              aria-disabled="false"
                              className="uiButton private-button private-button--default private-loading-button custom-color-button private-button--active private-button--non-link button-checkout-disabled"
                              data-button-use="tertiary"
                              aria-pressed="true"
                              onClick={() => onHandleClick()}
                            >
                              <span className="private-loading-button__content private-button--internal-spacing">
                                <div>
                                  <Trans text="following_order_button_tracking"></Trans>
                                </div>
                              </span>
                            </button>
                          </div>
                        )}
                        {(deal.delivery_tracking_link === "" ||
                          deal.delivery_tracking_link === null) && (
                            <div className="text-center">
                              <Trans text="following_order_subtitle_preparing"></Trans>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="hs-form__field-row__column">
                    <div className="rounded-border quote-terms">
                      <h4 className="m-bottom-8 text-center">
                        <Trans text="following_invoice_title"></Trans>
                      </h4>
                      <div className="m-bottom-0 is--heading-7 quote-rich-text-body link-obsidian-container">
                        <div className="text-center">
                          <p>
                            <Trans text="following_invoice_subtitle_email"></Trans>
                          </p>
                          <p></p>
                          <p>{company.e_mail_de_facturation}</p>
                          <p></p>
                          <button
                            data-selenium-test="quotes-purchase-btn"
                            data-loading="false"
                            data-loading-state="idle"
                            aria-disabled="false"
                            className="uiButton private-button private-button--default private-loading-button custom-color-button private-button--active private-button--non-link button-checkout-disabled"
                            data-button-use="tertiary"
                            aria-pressed="true"
                            onClick={() => onHandleBillingClick()}
                          >
                            <span className="private-loading-button__content private-button--internal-spacing">
                              <div>
                                <Trans text="billing_link"></Trans>
                              </div>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {deal.dealtype != "Equiscience" && (
            <PurchaseTermsText dealtype={deal.dealtype}></PurchaseTermsText>
          )}

          <div className="fixed-width">
            <div className="hs-form__row">
              <div className="hs-form__group">
                <div className="hs-form__field-row">
                  <div className="hs-form__field-row__column">
                    <div className="hs-form__field">
                      <label
                        id="purchaseTermsAcceptance-label"
                        className="hs-form__field__label  hs-form__field__checkbox-label"
                        data-required="true"
                      >
                        <span className="hs-form__field__checkbox__label-span">
                          <Trans text="purchase_terms_acceptance_checked"></Trans>{" "}
                          <a
                            href={quoteTermsLink}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                          >
                            <Trans text="purchase_terms_terms_and_conditions"></Trans>
                          </a>
                          .
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Quote
            deal={deal}
            lineItems={lineItems}
            company={company}
            expirationDate={null}
          ></Quote>
          <Footer deal={deal} salesOwner={salesOwner}></Footer>
        </div>
      </div>
    </div>
  );
}

export default Following;
