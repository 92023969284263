import { withTranslation } from "react-i18next";

function TransF({ t, text }) {
  return t(text);
}

function TransUpperCaseF({ t, text }) {
  // Renders the translated text in uppercase
  return t(text).toUpperCase();
}

function TransHTMLF({ t, text }) {
  // Renvoyer un élément React qui interprète le HTML
  return <span dangerouslySetInnerHTML={{ __html: t(text) }} />;
}

const Trans = withTranslation()(TransF);
const TransUpperCase = withTranslation()(TransUpperCaseF);
const TransHTML = withTranslation()(TransHTMLF)

export {
  Trans,
  TransUpperCase,
  TransHTML
};
