import React from "react";
import Trans from "./Trans.js";
import SignaturePad from "react-signature-canvas";
import { useState, forwardRef, useImperativeHandle, useRef } from "react";

const Signature = forwardRef((props, ref) => {

    const [imageURL, setImageURL] = useState(null); // create a state that will contain our image url

    const sigCanvas = useRef({});

    /* a function that uses the canvas ref to clear the canvas 
    via a method given by react-signature-canvas */
    const clear = () => sigCanvas.current.clear();

    /* a function that uses the canvas ref to trim the canvas 
    from white spaces via a method given by react-signature-canvas
    then saves it in our state */
    // const save = () => {
    //     if (sigCanvas.current.getTrimmedCanvas().width > 1 && sigCanvas.current.getTrimmedCanvas().height > 1) {
    //         setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    //         console.log(sigCanvas.current.getTrimmedCanvas())
    //     }
    // }

    // This is the function that the parent will call
    const executeFunction = () => {
        if (sigCanvas.current.getTrimmedCanvas().width > 1 && sigCanvas.current.getTrimmedCanvas().height > 1) {
            setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
            props.onSignature(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")); // Pass the result back to the parent via the callback function
        }
        else {
            props.onSignature("")
        }

    };

    // Use useImperativeHandle to expose functions to the parent component
    useImperativeHandle(ref, () => ({
        executeFunction,
    }));


    var quoteTermsClassname = "rounded-border quote-terms";

    switch (props.dealtype) {
        case "EQUIMETRE FLAT":
            quoteTermsClassname = "rounded-border quote-terms quote-terms-equimetre text-center";
            break;
        case "EQUIMETRE TROT":
            quoteTermsClassname = "rounded-border quote-terms quote-terms-equimetre text-center";
            break;
        case "EQUIMETRE VET":
            quoteTermsClassname =
                "rounded-border quote-terms quote-terms-equimetre-vet text-center";
            break;
        case "EQUISYM":
            quoteTermsClassname = "rounded-border quote-terms quote-terms-equisym text-center";
            break;
        case "Equiscience":
            quoteTermsClassname = "rounded-border quote-terms quote-terms-equiscience text-center";
            break;
        default:
            break;
    }

    return (
        <div className="fixed-width">
            <div className="quote-terms-and-signature-container">
                <div
                    className="quote-terms-container fixed-width"
                    data-selenium-test="quotes-terms-container"
                >
                    <div className={quoteTermsClassname}>
                        <h5 className="m-bottom-4">
                            <Trans text="purchase_terms_acceptance_signature"></Trans>
                        </h5>
                        <SignaturePad
                            ref={sigCanvas}
                            canvasProps={{
                                width: 400,   // Width of the canvas
                                height: 200,  // Height of the canvas
                                className: "signature-canvas",
                                style: { backgroundColor: "white", border: "1px solid #000" }
                            }}
                        />
                        <div>
                            <button onClick={clear} type="button">Clear</button>
                        </div>
                    </div>
                </div>
                {props.errorMessage && (
                    <div
                        id="email__error"
                        class="hs-form__field__alert hs-form__field__alert_error"
                    >
                        <span>{props.errorMessage}</span>
                    </div>
                )}
            </div>

        </div>
    );
})

export default Signature;
