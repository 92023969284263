import React from 'react';
import { PaymentElement, useCheckout } from "@stripe/react-stripe-js";
import PayButton from './PayButton';

const CheckoutForm = ({ formData }) => {
    const checkout = useCheckout();
    const paymentElementOptions = {
        fields: {
            billingDetails: {
                name: 'never',
                email: 'never',
                phone: 'never',
                address: 'never',
            }
        },
        layout: 'tabs'
    };

    return (
        <form>
            <PaymentElement options={paymentElementOptions} />
            <PayButton formData={formData} checkout={checkout}></PayButton>
        </form >
    )
};

export default CheckoutForm;