import React, { useEffect, useState } from 'react';

import Trans from "./Trans.js";
import { Document, Page } from 'react-pdf';
import 'pdfjs-dist/legacy/web/pdf_viewer.css'; // For styles
import "pdfjs-dist/legacy/build/pdf.worker.min.mjs";
import i18n from '../locale/i18n.js';

function PurchaseTermsViewer({ dealtype, onCheckBoxChange, errorMessage }) {

    const [pdfUrl, setPdfUrl] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [error, setError] = useState(null);

    const [apiUrl, setApiUrl] = useState(i18n.t("quote_terms_page"));

    useEffect(() => {
        const handleLanguageChange = () => {
            switch (dealtype) {
                case "EQUIMETRE FLAT":
                    setApiUrl(i18n.t("quote_terms_page"))
                    break;
                case "EQUIMETRE TROT":
                    setApiUrl(i18n.t("quote_terms_page"))
                    break;
                case "EQUIMETRE VET":
                    setApiUrl(i18n.t("quote_terms_page"))
                    break;
                case "EQUISYM":
                    setApiUrl(i18n.t("quote_terms_page_eqs"))
                    break;
                case "Equiscience":
                    break;
                default:
                    break;
            }
        };
        i18n.on('languageChanged', handleLanguageChange)
    }, [i18n])

    useEffect(() => {
        switch (dealtype) {
            case "EQUIMETRE FLAT":
                setApiUrl(i18n.t("quote_terms_page"))
                break;
            case "EQUIMETRE TROT":
                setApiUrl(i18n.t("quote_terms_page"))
                break;
            case "EQUIMETRE VET":
                setApiUrl(i18n.t("quote_terms_page"))
                break;
            case "EQUISYM":
                setApiUrl(i18n.t("quote_terms_page_eqs"))
                break;
            case "Equiscience":
                break;
            default:
                break;
        }
    }, [dealtype])

    useEffect(() => {
        const fetchPdf = async () => {
            try {
                // Fetch the PDF from the server
                const response = await fetch(apiUrl, {
                    headers: {
                        // Add any required headers, like Authorization
                        // 'Authorization': `Bearer your_token`
                    },
                    credentials: 'include', // If your server requires cookies
                });

                // Check if the response is OK
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                // Convert the response to a blob
                const blob = await response.blob();

                // Log the blob to see its content
                if (blob.size === 0) {
                    throw new Error('Blob is empty');
                }

                // Create an object URL for the blob
                const pdfBlobUrl = URL.createObjectURL(blob);
                setPdfUrl(pdfBlobUrl);
            } catch (error) {
                console.error('Error fetching the PDF:', error);
                setError('Failed to load PDF');
            }
        };

        fetchPdf();
    }, [apiUrl]);



    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1)
    };

    return (
        <div>
            <div className='fixed-width'>
                <h1 className="text-center m-bottom-4">
                    <Trans text="purchase_terms_terms_and_conditions"></Trans>
                </h1>
                <div className="text-center pdf-container">

                    <Document
                        file={pdfUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {numPages > 0 && (
                            <div className="pdf-page-container">
                                <Page pageNumber={pageNumber} />
                            </div>
                        )}
                    </Document>

                    <div className="navigation">
                        <button
                            type="button"
                            disabled={pageNumber <= 1}
                            onClick={() => setPageNumber(pageNumber - 1)}
                        >
                            <Trans text="previous"></Trans>
                        </button>
                        <button
                            type="button"
                            disabled={pageNumber >= numPages}
                            onClick={() => setPageNumber(pageNumber + 1)}
                        >
                            <Trans text="next"></Trans>
                        </button>
                    </div>
                    <p>
                        <Trans text="page"></Trans> {pageNumber} <Trans text="of"></Trans> {numPages}
                    </p>
                </div>
            </div>
            <div className="fixed-width text-center">
                <div className="hs-form__row">
                    <div className="hs-form__group">
                        <div className="hs-form__field-row">
                            <div className="hs-form__field-row__column">
                                <div className="hs-form__field">
                                    <div
                                        id="purchaseTermsAcceptance-description"
                                        className="hs-form__field__label hs-form__field__label_required"
                                    >
                                        <Trans text="purchase_terms_acceptance_title"></Trans> :
                                    </div>
                                    <label
                                        id="purchaseTermsAcceptance-label"
                                        className="hs-form__field__label  hs-form__field__checkbox-label"
                                        data-required="true"
                                    >
                                        <input
                                            id="purchaseTermsAcceptance-input"
                                            aria-labelledby=" purchaseTermsAcceptance-label"
                                            className="hs-form__field__input "
                                            type="checkbox"
                                            name="purchaseTermsAcceptance"
                                            aria-invalid="false"
                                            aria-required="true"
                                            aria-checked="false"
                                            value=""
                                            onChange={(e) => onCheckBoxChange(e)}
                                        ></input>
                                        <span className="hs-form__field__checkbox__label-span">
                                            <Trans text="purchase_terms_acceptance_subtitle"></Trans>{" "}
                                            <Trans text="purchase_terms_terms_and_conditions"></Trans>
                                            .
                                        </span>
                                    </label>
                                    {errorMessage && (
                                        <div
                                            id="email__error"
                                            class="hs-form__field__alert hs-form__field__alert_error"
                                        >
                                            <span>{errorMessage}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PurchaseTermsViewer;
