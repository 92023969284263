import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

//COMPONENTS
import { TransUpperCase, Trans } from "./Trans.js";

function BillingQuotesItemsTable({ deals }) {

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {["billing_quotes_name", "billing_invoices_date", "billing_quotes_link"].map((title) => (
                            <TableCell align="center">
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                    <Trans text={title}></Trans>
                                </Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {deals.map((row) => (
                        <TableRow
                            key={row.properties.hs_object_id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="center" component="th" scope="row">
                                {row.properties.titre_du_devis}
                            </TableCell>
                            <TableCell align="center">{(new Date(row.properties.createdate)).toLocaleDateString()}</TableCell>
                            <TableCell align="center">
                                <Link onClick={() => {
                                    window.open(row.properties.lien_du_devis, '_blank');
                                }}>
                                    <Trans text="billing_quotes_view_quote"></Trans>
                                </Link>
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default BillingQuotesItemsTable;
