//COMPONENTS
import LineItemsTable from "./LineItemsTable.js";
import QuoteTotal from "./QuoteTotal.js";
import { TransUpperCase, Trans } from "./Trans.js";

function Quote({ deal, lineItems, company, expirationDate }) {

  return (
    <div className="quote-line-items-and-services m-y-10">
      <div className="fixed-width">
        <h1 className="text-center m-bottom-4">
          <Trans text="line_items_title"></Trans>
        </h1>
        <LineItemsTable lineItems={lineItems}></LineItemsTable>
        <QuoteTotal
          deal={deal}
          lineItems={lineItems}
          company={company}
          expirationDate={expirationDate}
        ></QuoteTotal>

      </div>
    </div>
  );
}

export default Quote;
