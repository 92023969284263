//CSS
import "../styles/UserForms.css";
//COMPONENTS
import { TransUpperCase, Trans } from "./Trans.js";
import TextField from "./TextField.js";
import Checkbox from "./Checkbox.js";
import CountrySelect from "./CountrySelect.js";

function UserForms({
  deal,
  values,
  onChange,
  copyBillingToDelivery,
  copyBillingToEquimetre,
  onCheckBoxChange,
}) {
  const divStyle = { color: "rgb(255, 0, 0)" };

  return (
    <div className="quote-line-items-and-services m-y-2">
      <div className="fixed-width">
        <h1 className="text-center m-bottom-4">
          <Trans text="user_forms_title"></Trans>
        </h1>

        {/* Merci de remplir ... */}
        <div className="hs-form__row">
          <div className="hs-form__group">
            <div className="hs-form__richtext">
              <p>
                <Trans text="user_forms_subtitle"></Trans>
              </p>
            </div>
          </div>
        </div>

        {/* Facturation */}
        <div className="hs-form__row">
          <div className="hs-form__group">
            <div className="hs-form__richtext">
              <h2>
                <Trans text="user_forms_billing_title"></Trans>
              </h2>
              <p>
                <Trans text="user_forms_billing_subtitle"></Trans>
              </p>
            </div>
          </div>
        </div>

        {/* Prénom / Nom / Société */}
        <div className="hs-form__row">
          <div className="hs-form__group">
            <div className="hs-form__field-row">
              <div className="hs-form__field-row__column">
                <TextField
                  name="billingFirstname"
                  label={<Trans text="user_forms_billing_firstname"></Trans>}
                  required={values.billingFirstname.required}
                  value={values.billingFirstname.value}
                  onChange={(e) => onChange(e)}
                  errorMessage={values.billingFirstname.errorMessage}
                ></TextField>
              </div>
              <div className="hs-form__field-row__column">
                <TextField
                  name="billingLastname"
                  label={<Trans text="user_forms_billing_lastname"></Trans>}
                  required={values.billingLastname.required}
                  value={values.billingLastname.value}
                  onChange={(e) => onChange(e)}
                  errorMessage={values.billingLastname.errorMessage}
                ></TextField>
              </div>
              <div className="hs-form__field-row__column">
                <TextField
                  name="billingCompany"
                  label={<Trans text="user_forms_billing_company"></Trans>}
                  required={values.billingCompany.required}
                  value={values.billingCompany.value}
                  onChange={(e) => onChange(e)}
                  errorMessage={values.billingCompany.errorMessage}
                ></TextField>
              </div>
            </div>
          </div>
        </div>

        {/* Email / Téléphone */}
        <div className="hs-form__row">
          <div className="hs-form__group">
            <div className="hs-form__field-row">
              <div className="hs-form__field-row__column">
                <TextField
                  name="billingEmail"
                  label={<Trans text="user_forms_billing_email"></Trans>}
                  required={values.billingEmail.required}
                  value={values.billingEmail.value}
                  onChange={(e) => onChange(e)}
                  errorMessage={values.billingEmail.errorMessage}
                ></TextField>
              </div>
              <div className="hs-form__field-row__column">
                <TextField
                  name="billingPhone"
                  label={<Trans text="user_forms_billing_phone"></Trans>}
                  required={values.billingPhone.required}
                  value={values.billingPhone.value}
                  onChange={(e) => onChange(e)}
                  errorMessage={values.billingPhone.errorMessage}
                ></TextField>
              </div>
            </div>
          </div>
        </div>

        {/* Adresse Ligne 1-2-3 */}
        <div className="hs-form__row">
          <div className="hs-form__group">
            <div className="hs-form__field-row">
              <div className="hs-form__field-row__column">
                <TextField
                  name="billingAddressLine1"
                  label={<Trans text="user_forms_billing_addressline1"></Trans>}
                  required={values.billingAddressLine1.required}
                  value={values.billingAddressLine1.value}
                  onChange={(e) => onChange(e)}
                  errorMessage={values.billingAddressLine1.errorMessage}
                ></TextField>
              </div>
            </div>
          </div>
        </div>

        <div className="hs-form__row">
          <div className="hs-form__group">
            <div className="hs-form__field-row">
              <div className="hs-form__field-row__column">
                <TextField
                  name="billingAddressLine2"
                  label={<Trans text="user_forms_billing_addressline2"></Trans>}
                  required={values.billingAddressLine2.required}
                  value={values.billingAddressLine2.value}
                  onChange={(e) => onChange(e)}
                  errorMessage={values.billingAddressLine2.errorMessage}
                ></TextField>
              </div>
            </div>
          </div>
        </div>

        <div className="hs-form__row">
          <div className="hs-form__group">
            <div className="hs-form__field-row">
              <div className="hs-form__field-row__column">
                <TextField
                  name="billingAddressLine3"
                  label={<Trans text="user_forms_billing_addressline3"></Trans>}
                  required={values.billingAddressLine3.required}
                  value={values.billingAddressLine3.value}
                  onChange={(e) => onChange(e)}
                  errorMessage={values.billingAddressLine3.errorMessage}
                ></TextField>
              </div>
            </div>
          </div>
        </div>

        {/* Code Postal / Ville */}
        <div className="hs-form__row">
          <div className="hs-form__group">
            <div className="hs-form__field-row">
              <div className="hs-form__field-row__column">
                <TextField
                  name="billingZipcode"
                  label={<Trans text="user_forms_billing_zipcode"></Trans>}
                  required={values.billingZipcode.required}
                  value={values.billingZipcode.value}
                  onChange={(e) => onChange(e)}
                  errorMessage={values.billingZipcode.errorMessage}
                ></TextField>
              </div>
              <div className="hs-form__field-row__column">
                <TextField
                  name="billingCity"
                  label={<Trans text="user_forms_billing_city"></Trans>}
                  required={values.billingCity.required}
                  value={values.billingCity.value}
                  onChange={(e) => onChange(e)}
                  errorMessage={values.billingCity.errorMessage}
                ></TextField>
              </div>
            </div>
          </div>
        </div>

        {/* Pays */}
        <div className="hs-form__row">
          <div className="hs-form__group">
            <div className="hs-form__field-row">
              <div className="hs-form__field-row__column">
                <CountrySelect
                  name="billingCountry"
                  label={<Trans text="user_forms_billing_country"></Trans>}
                  required={values.billingCountry.required}
                  value={values.billingCountry.value}
                  onChange={(e) => onChange(e)}
                  errorMessage={values.billingCountry.errorMessage}
                ></CountrySelect>
              </div>
              <div className="hs-form__field-row__column">
                <TextField
                  name="billingStateCode"
                  label={<Trans text="user_forms_billing_state_code"></Trans>}
                  required={values.billingStateCode.required}
                  value={values.billingStateCode.value}
                  onChange={(e) => onChange(e)}
                  errorMessage={values.billingStateCode.errorMessage}
                ></TextField>
              </div>
            </div>
          </div>
        </div>

        {/* TVA */}
        <div className="hs-form__row">
          <div className="hs-form__group">
            <div className="hs-form__field-row">
              <div className="hs-form__field-row__column">
                <TextField
                  name="billingTVA"
                  label={
                    <Trans text="user_forms_billing_vatnumber_title"></Trans>
                  }
                  required={values.billingTVA.required}
                  value={values.billingTVA.value}
                  onChange={(e) => onChange(e)}
                  errorMessage={values.billingTVA.errorMessage}
                  placeholder={"Ex: FR40123456824"}
                ></TextField>
                <p style={divStyle}>
                  <Trans text="user_forms_billing_vatnumber_subtitle"></Trans>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Livraison */}
        {deal.dealtype != "Equiscience" && (
          <div className="hs-form__row">
            <div className="hs-form__group">
              <div className="hs-form__richtext">
                <h2>
                  <Trans text="user_forms_delivery_title"></Trans>
                </h2>
                <p>
                  <Trans text="user_forms_delivery_subtitle"></Trans>&nbsp;
                </p>
              </div>
            </div>
          </div>
        )}


        {/* Case à cocher */}
        {deal.dealtype != "Equiscience" && (
          <div className="hs-form__row">
            <div className="hs-form__group">
              <div className="hs-form__field-row">
                <div className="hs-form__field-row__column">
                  <Checkbox
                    name="copyBillingToDelivery"
                    label={
                      <Trans text="user_forms_billing_to_delivery_checkbox_title"></Trans>
                    }
                    required={false}
                    value={copyBillingToDelivery}
                    onChange={(e) => onCheckBoxChange(e)}
                    errorMessage={""}
                  ></Checkbox>
                </div>
              </div>
            </div>
          </div>
        )}


        {!copyBillingToDelivery && deal.dealtype != "Equiscience" && (
          <div>
            {/* Prénom / Nom / Société */}
            <div className="hs-form__row">
              <div className="hs-form__group">
                <div className="hs-form__field-row">
                  <div className="hs-form__field-row__column">
                    <TextField
                      name="deliveryFirstname"
                      label={
                        <Trans text="user_forms_delivery_firstname"></Trans>
                      }
                      required={values.deliveryFirstname.required}
                      value={
                        copyBillingToDelivery
                          ? values.billingFirstname.value
                          : values.deliveryFirstname.value
                      }
                      onChange={(e) => onChange(e)}
                      errorMessage={values.deliveryFirstname.errorMessage}
                      disabled={copyBillingToDelivery ? "disabled" : ""}
                    ></TextField>
                  </div>
                  <div className="hs-form__field-row__column">
                    <TextField
                      name="deliveryLastname"
                      label={
                        <Trans text="user_forms_delivery_lastname"></Trans>
                      }
                      required={values.deliveryLastname.required}
                      value={
                        copyBillingToDelivery
                          ? values.billingLastname.value
                          : values.deliveryLastname.value
                      }
                      onChange={(e) => onChange(e)}
                      errorMessage={values.deliveryLastname.errorMessage}
                      disabled={copyBillingToDelivery ? "disabled" : ""}
                    ></TextField>
                  </div>
                  <div className="hs-form__field-row__column">
                    <TextField
                      name="deliveryCompany"
                      label={<Trans text="user_forms_delivery_company"></Trans>}
                      required={values.deliveryCompany.required}
                      value={
                        copyBillingToDelivery
                          ? values.billingCompany.value
                          : values.deliveryCompany.value
                      }
                      onChange={(e) => onChange(e)}
                      errorMessage={values.deliveryCompany.errorMessage}
                      disabled={copyBillingToDelivery ? "disabled" : ""}
                    ></TextField>
                  </div>
                </div>
              </div>
            </div>

            {/* Email / Téléphone */}
            <div className="hs-form__row">
              <div className="hs-form__group">
                <div className="hs-form__field-row">
                  <div className="hs-form__field-row__column">
                    <TextField
                      name="deliveryEmail"
                      label={<Trans text="user_forms_delivery_email"></Trans>}
                      required={values.deliveryEmail.required}
                      value={
                        copyBillingToDelivery
                          ? values.billingEmail.value
                          : values.deliveryEmail.value
                      }
                      onChange={(e) => onChange(e)}
                      errorMessage={values.deliveryEmail.errorMessage}
                      disabled={copyBillingToDelivery ? "disabled" : ""}
                    ></TextField>
                  </div>
                  <div className="hs-form__field-row__column">
                    <TextField
                      name="deliveryPhone"
                      label={<Trans text="user_forms_delivery_phone"></Trans>}
                      required={values.deliveryPhone.required}
                      value={
                        copyBillingToDelivery
                          ? values.billingPhone.value
                          : values.deliveryPhone.value
                      }
                      onChange={(e) => onChange(e)}
                      errorMessage={values.deliveryPhone.errorMessage}
                      disabled={copyBillingToDelivery ? "disabled" : ""}
                    ></TextField>
                  </div>
                </div>
              </div>
            </div>

            {/* Adresse Ligne 1-2-3 */}
            <div className="hs-form__row">
              <div className="hs-form__group">
                <div className="hs-form__field-row">
                  <div className="hs-form__field-row__column">
                    <TextField
                      name="deliveryAddressLine1"
                      label={
                        <Trans text="user_forms_delivery_addressline1"></Trans>
                      }
                      required={values.deliveryAddressLine1.required}
                      value={
                        copyBillingToDelivery
                          ? values.billingAddressLine1.value
                          : values.deliveryAddressLine1.value
                      }
                      onChange={(e) => onChange(e)}
                      errorMessage={values.deliveryAddressLine1.errorMessage}
                      disabled={copyBillingToDelivery ? "disabled" : ""}
                    ></TextField>
                  </div>
                </div>
              </div>
            </div>

            <div className="hs-form__row">
              <div className="hs-form__group">
                <div className="hs-form__field-row">
                  <div className="hs-form__field-row__column">
                    <TextField
                      name="deliveryAddressLine2"
                      label={
                        <Trans text="user_forms_delivery_addressline2"></Trans>
                      }
                      required={values.deliveryAddressLine2.required}
                      value={
                        copyBillingToDelivery
                          ? values.billingAddressLine2.value
                          : values.deliveryAddressLine2.value
                      }
                      onChange={(e) => onChange(e)}
                      errorMessage={values.deliveryAddressLine2.errorMessage}
                      disabled={copyBillingToDelivery ? "disabled" : ""}
                    ></TextField>
                  </div>
                </div>
              </div>
            </div>

            <div className="hs-form__row">
              <div className="hs-form__group">
                <div className="hs-form__field-row">
                  <div className="hs-form__field-row__column">
                    <TextField
                      name="deliveryAddressLine3"
                      label={
                        <Trans text="user_forms_delivery_addressline3"></Trans>
                      }
                      required={values.deliveryAddressLine3.required}
                      value={
                        copyBillingToDelivery
                          ? values.billingAddressLine3.value
                          : values.deliveryAddressLine3.value
                      }
                      onChange={(e) => onChange(e)}
                      errorMessage={values.deliveryAddressLine3.errorMessage}
                      disabled={copyBillingToDelivery ? "disabled" : ""}
                    ></TextField>
                  </div>
                </div>
              </div>
            </div>

            {/* Code Postal / Ville */}
            <div className="hs-form__row">
              <div className="hs-form__group">
                <div className="hs-form__field-row">
                  <div className="hs-form__field-row__column">
                    <TextField
                      name="deliveryZipcode"
                      label={<Trans text="user_forms_delivery_zipcode"></Trans>}
                      required={values.deliveryZipcode.required}
                      value={
                        copyBillingToDelivery
                          ? values.billingZipcode.value
                          : values.deliveryZipcode.value
                      }
                      onChange={(e) => onChange(e)}
                      errorMessage={values.deliveryZipcode.errorMessage}
                      disabled={copyBillingToDelivery ? "disabled" : ""}
                    ></TextField>
                  </div>
                  <div className="hs-form__field-row__column">
                    <TextField
                      name="deliveryCity"
                      label={<Trans text="user_forms_delivery_city"></Trans>}
                      required={values.deliveryCity.required}
                      value={
                        copyBillingToDelivery
                          ? values.billingCity.value
                          : values.deliveryCity.value
                      }
                      onChange={(e) => onChange(e)}
                      errorMessage={values.deliveryCity.errorMessage}
                      disabled={copyBillingToDelivery ? "disabled" : ""}
                    ></TextField>
                  </div>
                </div>
              </div>
            </div>

            {/* Pays / State code */}
            <div className="hs-form__row">
              <div className="hs-form__group">
                <div className="hs-form__field-row">
                  <div className="hs-form__field-row__column">
                    <CountrySelect
                      name="deliveryCountry"
                      label={<Trans text="user_forms_delivery_country"></Trans>}
                      required={values.deliveryCountry.required}
                      value={
                        copyBillingToDelivery
                          ? values.billingCountry.value
                          : values.deliveryCountry.value
                      }
                      onChange={(e) => onChange(e)}
                      errorMessage={values.deliveryCountry.errorMessage}
                      disabled={copyBillingToDelivery ? "disabled" : ""}
                    ></CountrySelect>
                    <p>&nbsp;</p>
                  </div>
                  <div className="hs-form__field-row__column">
                    <TextField
                      name="deliveryStateCode"
                      label={
                        <Trans text="user_forms_delivery_state_code"></Trans>
                      }
                      required={values.deliveryStateCode.required}
                      value={
                        copyBillingToDelivery
                          ? values.billingStateCode.value
                          : values.deliveryStateCode.value
                      }
                      onChange={(e) => onChange(e)}
                      errorMessage={values.deliveryStateCode.errorMessage}
                      disabled={copyBillingToDelivery ? "disabled" : ""}
                    ></TextField>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Compte Equimetre/Equisym */}
        {deal.dealtype != "Equiscience" && (
          <div className="hs-form__row">
            <div className="hs-form__group">
              <div className="hs-form__richtext">
                <h2>
                  {deal.dealtype === "EQUISYM" ? (
                    <Trans text="user_forms_equisym_title"></Trans>
                  ) : (
                    <Trans text="user_forms_equimetre_title"></Trans>
                  )}
                </h2>
                <p>
                  {deal.dealtype === "EQUISYM" ? (
                    <Trans text="user_forms_equisym_subtitle"></Trans>
                  ) : (
                    <Trans text="user_forms_equimetre_subtitle"></Trans>
                  )}
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Case à cocher */}
        {deal.dealtype != "Equiscience" && (
          <div className="hs-form__row">
            <div className="hs-form__group">
              <div className="hs-form__field-row">
                <div className="hs-form__field-row__column">
                  <Checkbox
                    name="copyBillingToEquimetre"
                    label={
                      <Trans text="user_forms_billing_to_equimetre_checkbox_title"></Trans>
                    }
                    required={false}
                    value={copyBillingToEquimetre}
                    onChange={(e) => onCheckBoxChange(e)}
                    errorMessage={""}
                  ></Checkbox>
                </div>
              </div>
            </div>
          </div>
        )}

        {!copyBillingToEquimetre && deal.dealtype != "Equiscience" && (
          <div>
            <div className="hs-form__row">
              <div className="hs-form__group">
                <div className="hs-form__field-row">
                  <div className="hs-form__field-row__column">
                    <TextField
                      name="equimetreEmail"
                      label={
                        deal.dealtype === "EQUISYM" ? (
                          <Trans text="user_forms_equisym_email"></Trans>
                        ) : (
                          <Trans text="user_forms_equimetre_email"></Trans>
                        )
                      }
                      required={values.equimetreEmail.required}
                      value={copyBillingToEquimetre ? values.billingEmail.value : values.equimetreEmail.value}
                      onChange={(e) => onChange(e)}
                      errorMessage={values.equimetreEmail.errorMessage}
                      disabled={copyBillingToEquimetre ? "disabled" : ""}
                    ></TextField>
                  </div>
                </div>
              </div>
            </div>

            <div className="hs-form__row">
              <div className="hs-form__group">
                <div className="hs-form__field-row">
                  <div className="hs-form__field-row__column">
                    <TextField
                      name="equimetreFirstname"
                      label={
                        deal.dealtype === "EQUISYM" ? (
                          <Trans text="user_forms_equisym_firstname"></Trans>
                        ) : (
                          <Trans text="user_forms_equimetre_firstname"></Trans>
                        )
                      }
                      required={values.equimetreFirstname.required}
                      value={copyBillingToEquimetre ? values.billingFirstname.value : values.equimetreFirstname.value}
                      onChange={(e) => onChange(e)}
                      errorMessage={values.equimetreFirstname.errorMessage}
                      disabled={copyBillingToEquimetre ? "disabled" : ""}
                    ></TextField>
                  </div>
                  <div className="hs-form__field-row__column">
                    <TextField
                      name="equimetreLastname"
                      label={
                        deal.dealtype === "EQUISYM" ? (
                          <Trans text="user_forms_equisym_lastname"></Trans>
                        ) : (
                          <Trans text="user_forms_equimetre_lastname"></Trans>
                        )
                      }
                      required={values.equimetreLastname.required}
                      value={copyBillingToEquimetre ? values.billingLastname.value : values.equimetreLastname.value}
                      onChange={(e) => onChange(e)}
                      errorMessage={values.equimetreLastname.errorMessage}
                      disabled={copyBillingToEquimetre ? "disabled" : ""}
                    ></TextField>
                  </div>
                </div>
              </div>
            </div>

            <div className="hs-form__row">
              <div className="hs-form__group">
                <div className="hs-form__field-row">
                  <div className="hs-form__field-row__column">
                    <div className="hs-form__field">
                      <label
                        id="equimetreRole-label"
                        htmlFor="equimetreRole-input"
                        className="hs-form__field__label "
                        data-required="false"
                      >
                        <span>
                          {deal.dealtype === "EQUISYM" ? (
                            <Trans text="user_forms_equisym_role"></Trans>
                          ) : (
                            <Trans text="user_forms_equimetre_role"></Trans>
                          )}
                        </span>
                      </label>
                      <select
                        id="equimetreRole-input"
                        aria-labelledby="equimetreRole-label"
                        className="hs-form__field__input"
                        name="equimetreRole"
                        aria-invalid="false"
                        aria-required="false"
                        value={values.equimetreRole.value}
                        onChange={(e) => onChange(e)}
                      >
                        <option label="-" value="" disabled=""></option>
                        <option label="Breeder" value="Breeder">
                          Breeder
                        </option>
                        <option label="Broker" value="Broker">
                          Broker
                        </option>
                        <option label="Care" value="Care">
                          Care
                        </option>
                        <option label="Endurance" value="Endurance">
                          Endurance
                        </option>
                        <option label="Race Horse Owner" value="Horse Owner">
                          Race Horse Owner
                        </option>
                        <option label="Other" value="Other">
                          Other
                        </option>
                        <option label="Pre Trainer" value="Pre Trainer">
                          Pre Trainer
                        </option>
                        <option label="Retailer" value="Retailer">
                          Retailer
                        </option>
                        <option label="Sports" value="Sports">
                          Sports
                        </option>
                        <option label="Trainer Flat" value="Trainer Flat">
                          Trainer Flat
                        </option>
                        <option label="Trainer Jump" value="Trainer Jump">
                          Trainer Jump
                        </option>
                        <option label="Trainer Trot" value="Trainer Trot">
                          Trainer Trot
                        </option>
                        <option label="Vet - Science" value="Vet - Science">
                          Vet - Science
                        </option>
                        <option
                          label="Corporate - Hippodromes"
                          value="Corporate - Hippodromes"
                        >
                          Corporate - Hippodromes
                        </option>
                        <option label="Team Arioneo" value="Team Arioneo">
                          Team Arioneo
                        </option>
                        <option label="Candidatures" value="Candidatures">
                          Candidatures
                        </option>
                        <option label="Investor" value="Investor">
                          Investor
                        </option>
                      </select>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserForms;
