import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { green, red, grey } from '@mui/material/colors';
import { DonutLarge, HourglassEmpty } from '@mui/icons-material';

//UTILS
import { getCurrencyText } from "../utilities/Utils.js";

//COMPONENTS
import { TransUpperCase, Trans } from "./Trans.js";

function BillingSubscriptionsItemsRow({ lineItem }) {

    var a = new Date(lineItem.next_invoice_date * 1000);

    var chipLabel = ""
    var chipColor = ""
    var avatarColor = ""
    var avatarIcon = ""

    console.log(lineItem)

    switch (lineItem.status) {
        case "active":
            if (lineItem.next_invoice_date == null) {
                chipLabel = <Trans text="paused" />
                chipColor = "default"
                avatarColor = grey[500]
            }
            else {
                chipLabel = <Trans text="active" />
                chipColor = "success"
                avatarColor = green[500]
            }
            avatarIcon = <DonutLarge />
            break;

        case "past_due":
            chipLabel = <Trans text="past_due" />
            chipColor = "error"
            avatarColor = red[500]
            avatarIcon = <DonutLarge />
            break;

        case "not_started":
            chipLabel = <div><Trans text="start_on" /> {a.toLocaleDateString()}</div>
            chipColor = "default"
            avatarColor = grey[500]
            avatarIcon = <HourglassEmpty />
            break;

        default:
            break;
    }

    return (
        <Stack direction={"row"} spacing={1} alignItems="center">
            <Avatar sx={{ bgcolor: avatarColor }} variant="rounded">
                {avatarIcon}
            </Avatar>
            <Stack spacing={0}>
                <Stack direction="row" spacing={1}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {lineItem.name}
                        {lineItem.quantity > 1 && (
                            " X " + lineItem.quantity
                        )}
                    </Typography>
                    <Chip label={chipLabel} color={chipColor} size="small" />
                </Stack>

                <Typography variant="body1" sx={{ color: "#606060" }}>
                    {lineItem.next_invoice_date == null && (
                        <div>
                            <Trans text="billing" /> <Trans text={lineItem.interval} /> <Trans text="for" /> {getCurrencyText(lineItem.next_invoice_amount / 100, lineItem.currency)}
                        </div>
                    ) || (
                            <div>
                                <Trans text="billing" /> <Trans text={lineItem.interval} /> • <Trans text="billing_next_invoice_on" /> {a.toLocaleDateString()} <Trans text="for" /> {getCurrencyText(lineItem.next_invoice_amount / 100, lineItem.currency)}
                            </div>
                        )}
                </Typography>
            </Stack >
        </Stack>

    );
}

export default BillingSubscriptionsItemsRow;
