import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

//COMPONENTS
import { TransUpperCase, Trans } from "./Trans.js";

function BillingInformations({ invoices, customer }) {

    return (
        <div>
            {invoices != null && customer != null && (
                <div className="fixed-width">
                    <Stack spacing={2}>
                        <Typography variant="h5" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            <Trans text="billing_informations" />
                        </Typography>
                        <Typography variant="subtitle2" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            <Trans text="billing_id" />
                        </Typography>
                        {customer.id}
                        <Typography variant="subtitle2" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            <Trans text="billing_email" />
                        </Typography>
                        {customer.email}
                        <Typography variant="subtitle2" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            <Trans text="billing_customer_since" />
                        </Typography>
                        {(new Date(customer.created * 1000)).toLocaleDateString()}
                    </Stack>
                </div>
            )
            }
        </div>
    );
}

export default BillingInformations;
