import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import { Link } from "react-router-dom";

import { IconButton, Button, TextField, List, ListItem, ListItemText } from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import { styled } from "@mui/material/styles";

import i18n from "../locale/i18n.js";
import { useState, useEffect, useRef, useCallback } from "react";

import backgroundImage from "../assets/background.png"; // Ensure the image is in ../assets/
import logoImage from "../assets/logo_eqsc.png"; // Logo image

import frenchFlag from "../assets/flag-fr.png"; // French flag
import britishFlag from "../assets/flag-uk.png"; // British flag

import arioneoLogo from "../assets/arioneo.png"; // new background logo
import secureLogo from "../assets/secure.png";

import { loadStripe } from '@stripe/stripe-js';

import { CheckoutProvider } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm.js';

//COMPONENTS
import { TransUpperCase, Trans, TransHTML } from "./Trans.js";
import PromotionCode from './PromotionCode.js';
import CurrencySelection from './CurrencySelection.js';

import { useTranslation } from "react-i18next";

//CONST
const stripe = loadStripe("pk_live_51PGI4108JryCWfz4BJrAQDkRxE1fCgwE4GtlRfjTaTcztum727HO1phru0b4n5wZz3NdrUGiN2nzXpERru5SLAQy00kmZ4TLoI", {
    betas: ['custom_checkout_beta_5'],
});

const defaultTheme = createTheme({
    typography: {
        fontFamily: "Montserrat, sans-serif",
    },
    components: {
        MuiFormLabel: {
            styleOverrides: {
                asterisk: {
                    color: 'red',  // color of the asterisk
                },
            },
        },
    },
});

const Background = styled("div")({
    position: "fixed",
    width: "100%",
    minHeight: "100vh",
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    zIndex: -1, // Ensure it stays behind everything
});

const WatermarkLogo = styled("img")({
    position: "fixed",
    bottom: "12px",
    right: "12px",
    width: "80px",
    height: "auto",
    opacity: 0.8,
    zIndex: 0,
});

const TransparentNavbar = styled(AppBar)({
    background: "transparent", // Fully transparent
    boxShadow: "none", // Removes shadow
    color: "white", // Ensures visibility on different backgrounds
});

const HomeButton = styled(Button)({
    backgroundColor: "#B2A494", // Custom background color
    color: "white", // White text
    textTransform: "none", // Keeps text in normal case (not uppercase)
    padding: "6px 16px",
    fontSize: "1rem", // Increased font size
    fontFamily: "Lato, sans-serif", // Apply Lato font
    fontWeight: 400, // Regular
});

const Logo = styled("img")({
    height: "86px", // Adjust based on your design
    objectFit: "contain",
    marginTop: "24px", // Adds spacing above the logo
    marginBottom: "24px", // Adds spacing below the logo
});

const ContactButton = styled(Button)({
    color: "white",
    textTransform: "none",
    fontSize: "1rem", // Increased font size
    fontFamily: "Lato, sans-serif", // Apply Lato font
    fontWeight: 400, // Regular
});

const FlagButton = styled(IconButton)({
    padding: "5px",
    margin: "0 5px", // Small spacing between buttons
    "& img": {
        height: "24px", // Adjust flag size
        width: "36px",
        objectFit: "cover",
    },
});

const CenterContainer = styled("div")({
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
});

const InfoBox = styled(Box)({
    background: "white",
    padding: "20px",
    borderRadius: "4px",
    boxShadow: "12px 12px 20px rgba(0, 0, 0, 0.4)", // Shadow only at the bottom
    color: "black",
    fontFamily: "Montserrat, sans-serif", // Apply Montserrat to InfoBox
    fontSize: "1rem",
    lineHeight: "1",
    // Make the container flex-column so we can push PriceBox to the bottom.
    display: "flex",
    flexDirection: "column",
});

const PriceBox = styled(Box)({
    backgroundColor: "#B2A494",
    color: "white",
    fontWeight: 300,
    fontFamily: "Montserrat, sans-serif", // Apply Montserrat to InfoBox
    fontStyle: "italic",
    fontSize: "2rem",
    textAlign: "center",
    padding: "12px 32px",
    borderRadius: "32px",
    marginTop: "auto",     // pushes to bottom
    alignSelf: "center",  // centers horizontally
    marginTop: "20px",     // spacing above
    display: "inline-block", // ensures width shrinks
    width: "auto",           // auto width 
});

const FormContainer = styled("div")({
    background: "white",
    padding: "20px",
    borderRadius: "4px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
});

const CenteredGrid = styled(Grid)({
    height: "calc(100vh - 48px)", // Subtract the AppBar (Toolbar) height
    marginTop: "0px",            // Push content below the fixed AppBar
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "5%",
    paddingRight: "5%",
});

const Price = styled("span")({
    fontSize: "2rem",
});

const StrikePrice = styled("span")({
    textDecoration: 'line-through',
    fontSize: "2rem",
});

const SmallerPrice = styled("span")({
    fontSize: "1.2rem",
    marginLeft: "8px",
});

const Eqsc = ({ encryptedKey }) => {

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const { t } = useTranslation(); // Use the translation hook


    const [clientSecret, setClientSecret] = useState(null);
    const [amount, setAmount] = useState(0.0)
    const [discountAmount, setDiscountAmount] = useState(0.0)
    const [taxAmount, setTaxAmount] = useState(0.0)
    const [currency, setCurrency] = useState("")
    const [code, setCode] = useState(null);
    const [error, setError] = useState(null);
    const [needVat, setNeedVat] = useState(false);

    useEffect(() => {

        fetch('/create-checkout-session', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ currency: currency, withTax: needVat })
        })
            .then((response) => response.json())
            .then((json) => {
                console.log(json)
                setClientSecret(json.client_secret)
                setAmount(json.amount_subtotal)
                setDiscountAmount(json.total_details.amount_discount)
                setTaxAmount(json.total_details.amount_tax)
                setCurrency(json.currency)
            })
    }, []);

    const fetchCode = async () => {
        try {

            if (!encryptedKey) {
                return
            }

            // 2. Send the encryptedKey to the backend for decryption using fetch
            const response = await fetch('/retrieve-code', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ encryptedKey }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to retrieve code.');
            }

            const data = await response.json();

            if (data && data.code) {
                setCode(data.code);
            } else {
                throw new Error('Code not found in the response.');
            }
        } catch (err) {
            console.error('Error retrieving code:', err.message);
            setError(err.message);
        }
    };

    useEffect(() => {
        fetchCode();
    }, [encryptedKey]);

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        company: "",
        vat: "",
        address1: "",
        address2: "",
        zip: "",
        city: "",
        country: "",
        phone: "",
        email: "",
    });

    const countries = [
        { code: "AF", label: "Afghanistan" },
        { code: "AX", label: "Åland Islands" },
        { code: "AL", label: "Albania" },
        { code: "DZ", label: "Algeria" },
        { code: "AS", label: "American Samoa" },
        { code: "AD", label: "Andorra" },
        { code: "AO", label: "Angola" },
        { code: "AI", label: "Anguilla" },
        { code: "AQ", label: "Antarctica" },
        { code: "AG", label: "Antigua and Barbuda" },
        { code: "AR", label: "Argentina" },
        { code: "AM", label: "Armenia" },
        { code: "AW", label: "Aruba" },
        { code: "AU", label: "Australia" },
        { code: "AT", label: "Austria" },
        { code: "AZ", label: "Azerbaijan" },
        { code: "BS", label: "Bahamas" },
        { code: "BH", label: "Bahrain" },
        { code: "BD", label: "Bangladesh" },
        { code: "BB", label: "Barbados" },
        { code: "BY", label: "Belarus" },
        { code: "BE", label: "Belgium" },
        { code: "BZ", label: "Belize" },
        { code: "BJ", label: "Benin" },
        { code: "BM", label: "Bermuda" },
        { code: "BT", label: "Bhutan" },
        { code: "BO", label: "Bolivia" },
        { code: "BQ", label: "Bonaire, Sint Eustatius and Saba" },
        { code: "BA", label: "Bosnia and Herzegovina" },
        { code: "BW", label: "Botswana" },
        { code: "BV", label: "Bouvet Island" },
        { code: "BR", label: "Brazil" },
        { code: "IO", label: "British Indian Ocean Territory" },
        { code: "BN", label: "Brunei Darussalam" },
        { code: "BG", label: "Bulgaria" },
        { code: "BF", label: "Burkina Faso" },
        { code: "BI", label: "Burundi" },
        { code: "KH", label: "Cambodia" },
        { code: "CM", label: "Cameroon" },
        { code: "CA", label: "Canada" },
        { code: "CV", label: "Cape Verde" },
        { code: "KY", label: "Cayman Islands" },
        { code: "CF", label: "Central African Republic" },
        { code: "TD", label: "Chad" },
        { code: "CL", label: "Chile" },
        { code: "CN", label: "China" },
        { code: "CX", label: "Christmas Island" },
        { code: "CC", label: "Cocos (Keeling) Islands" },
        { code: "CO", label: "Colombia" },
        { code: "KM", label: "Comoros" },
        { code: "CG", label: "Congo" },
        { code: "CD", label: "Congo, the Democratic Republic of the" },
        { code: "CK", label: "Cook Islands" },
        { code: "CR", label: "Costa Rica" },
        { code: "CI", label: "Côte d'Ivoire" },
        { code: "HR", label: "Croatia" },
        { code: "CU", label: "Cuba" },
        { code: "CW", label: "Curaçao" },
        { code: "CY", label: "Cyprus" },
        { code: "CZ", label: "Czech Republic" },
        { code: "DK", label: "Denmark" },
        { code: "DJ", label: "Djibouti" },
        { code: "DM", label: "Dominica" },
        { code: "DO", label: "Dominican Republic" },
        { code: "EC", label: "Ecuador" },
        { code: "EG", label: "Egypt" },
        { code: "SV", label: "El Salvador" },
        { code: "GQ", label: "Equatorial Guinea" },
        { code: "ER", label: "Eritrea" },
        { code: "EE", label: "Estonia" },
        { code: "ET", label: "Ethiopia" },
        { code: "FK", label: "Falkland Islands (Malvinas)" },
        { code: "FO", label: "Faroe Islands" },
        { code: "FJ", label: "Fiji" },
        { code: "FI", label: "Finland" },
        { code: "FR", label: "France" },
        { code: "GF", label: "French Guiana" },
        { code: "PF", label: "French Polynesia" },
        { code: "TF", label: "French Southern Territories" },
        { code: "GA", label: "Gabon" },
        { code: "GM", label: "Gambia" },
        { code: "GE", label: "Georgia" },
        { code: "DE", label: "Germany" },
        { code: "GH", label: "Ghana" },
        { code: "GI", label: "Gibraltar" },
        { code: "GR", label: "Greece" },
        { code: "GL", label: "Greenland" },
        { code: "GD", label: "Grenada" },
        { code: "GP", label: "Guadeloupe" },
        { code: "GU", label: "Guam" },
        { code: "GT", label: "Guatemala" },
        { code: "GG", label: "Guernsey" },
        { code: "GN", label: "Guinea" },
        { code: "GW", label: "Guinea-Bissau" },
        { code: "GY", label: "Guyana" },
        { code: "HT", label: "Haiti" },
        { code: "HM", label: "Heard Island and McDonald Islands" },
        { code: "VA", label: "Holy See (Vatican City State)" },
        { code: "HN", label: "Honduras" },
        { code: "HK", label: "Hong Kong" },
        { code: "HU", label: "Hungary" },
        { code: "IS", label: "Iceland" },
        { code: "IN", label: "India" },
        { code: "ID", label: "Indonesia" },
        { code: "IR", label: "Iran, Islamic Republic of" },
        { code: "IQ", label: "Iraq" },
        { code: "IE", label: "Ireland" },
        { code: "IM", label: "Isle of Man" },
        { code: "IL", label: "Israel" },
        { code: "IT", label: "Italy" },
        { code: "JM", label: "Jamaica" },
        { code: "JP", label: "Japan" },
        { code: "JE", label: "Jersey" },
        { code: "JO", label: "Jordan" },
        { code: "KZ", label: "Kazakhstan" },
        { code: "KE", label: "Kenya" },
        { code: "KI", label: "Kiribati" },
        { code: "KP", label: "Korea, Democratic People's Republic of" },
        { code: "KR", label: "Korea, Republic of" },
        { code: "KW", label: "Kuwait" },
        { code: "KG", label: "Kyrgyzstan" },
        { code: "LA", label: "Lao People's Democratic Republic" },
        { code: "LV", label: "Latvia" },
        { code: "LB", label: "Lebanon" },
        { code: "LS", label: "Lesotho" },
        { code: "LR", label: "Liberia" },
        { code: "LY", label: "Libya" },
        { code: "LI", label: "Liechtenstein" },
        { code: "LT", label: "Lithuania" },
        { code: "LU", label: "Luxembourg" },
        { code: "MO", label: "Macao" },
        { code: "MK", label: "Macedonia, the former Yugoslav Republic of" },
        { code: "MG", label: "Madagascar" },
        { code: "MW", label: "Malawi" },
        { code: "MY", label: "Malaysia" },
        { code: "MV", label: "Maldives" },
        { code: "ML", label: "Mali" },
        { code: "MT", label: "Malta" },
        { code: "MH", label: "Marshall Islands" },
        { code: "MQ", label: "Martinique" },
        { code: "MR", label: "Mauritania" },
        { code: "MU", label: "Mauritius" },
        { code: "YT", label: "Mayotte" },
        { code: "MX", label: "Mexico" },
        { code: "FM", label: "Micronesia, Federated States of" },
        { code: "MD", label: "Moldova, Republic of" },
        { code: "MC", label: "Monaco" },
        { code: "MN", label: "Mongolia" },
        { code: "ME", label: "Montenegro" },
        { code: "MS", label: "Montserrat" },
        { code: "MA", label: "Morocco" },
        { code: "MZ", label: "Mozambique" },
        { code: "MM", label: "Myanmar" },
        { code: "NA", label: "Namibia" },
        { code: "NR", label: "Nauru" },
        { code: "NP", label: "Nepal" },
        { code: "NL", label: "Netherlands" },
        { code: "NC", label: "New Caledonia" },
        { code: "NZ", label: "New Zealand" },
        { code: "NI", label: "Nicaragua" },
        { code: "NE", label: "Niger" },
        { code: "NG", label: "Nigeria" },
        { code: "NU", label: "Niue" },
        { code: "NF", label: "Norfolk Island" },
        { code: "MP", label: "Northern Mariana Islands" },
        { code: "NO", label: "Norway" },
        { code: "OM", label: "Oman" },
        { code: "PK", label: "Pakistan" },
        { code: "PW", label: "Palau" },
        { code: "PS", label: "Palestine, State of" },
        { code: "PA", label: "Panama" },
        { code: "PG", label: "Papua New Guinea" },
        { code: "PY", label: "Paraguay" },
        { code: "PE", label: "Peru" },
        { code: "PH", label: "Philippines" },
        { code: "PN", label: "Pitcairn" },
        { code: "PL", label: "Poland" },
        { code: "PT", label: "Portugal" },
        { code: "PR", label: "Puerto Rico" },
        { code: "QA", label: "Qatar" },
        { code: "RE", label: "Réunion" },
        { code: "RO", label: "Romania" },
        { code: "RU", label: "Russian Federation" },
        { code: "RW", label: "Rwanda" },
        { code: "BL", label: "Saint Barthélemy" },
        { code: "SH", label: "Saint Helena, Ascension and Tristan da Cunha" },
        { code: "KN", label: "Saint Kitts and Nevis" },
        { code: "LC", label: "Saint Lucia" },
        { code: "MF", label: "Saint Martin (French part)" },
        { code: "PM", label: "Saint Pierre and Miquelon" },
        { code: "VC", label: "Saint Vincent and the Grenadines" },
        { code: "WS", label: "Samoa" },
        { code: "SM", label: "San Marino" },
        { code: "ST", label: "Sao Tome and Principe" },
        { code: "SA", label: "Saudi Arabia" },
        { code: "SN", label: "Senegal" },
        { code: "RS", label: "Serbia" },
        { code: "SC", label: "Seychelles" },
        { code: "SL", label: "Sierra Leone" },
        { code: "SG", label: "Singapore" },
        { code: "SX", label: "Sint Maarten (Dutch part)" },
        { code: "SK", label: "Slovakia" },
        { code: "SI", label: "Slovenia" },
        { code: "SB", label: "Solomon Islands" },
        { code: "SO", label: "Somalia" },
        { code: "ZA", label: "South Africa" },
        { code: "GS", label: "South Georgia and the South Sandwich Islands" },
        { code: "SS", label: "South Sudan" },
        { code: "ES", label: "Spain" },
        { code: "LK", label: "Sri Lanka" },
        { code: "SD", label: "Sudan" },
        { code: "SR", label: "Suriname" },
        { code: "SJ", label: "Svalbard and Jan Mayen" },
        { code: "SZ", label: "Swaziland" },
        { code: "SE", label: "Sweden" },
        { code: "CH", label: "Switzerland" },
        { code: "SY", label: "Syrian Arab Republic" },
        { code: "TW", label: "Taiwan, Province of China" },
        { code: "TJ", label: "Tajikistan" },
        { code: "TZ", label: "Tanzania, United Republic of" },
        { code: "TH", label: "Thailand" },
        { code: "TL", label: "Timor-Leste" },
        { code: "TG", label: "Togo" },
        { code: "TK", label: "Tokelau" },
        { code: "TO", label: "Tonga" },
        { code: "TT", label: "Trinidad and Tobago" },
        { code: "TN", label: "Tunisia" },
        { code: "TR", label: "Turkey" },
        { code: "TM", label: "Turkmenistan" },
        { code: "TC", label: "Turks and Caicos Islands" },
        { code: "TV", label: "Tuvalu" },
        { code: "UG", label: "Uganda" },
        { code: "UA", label: "Ukraine" },
        { code: "AE", label: "United Arab Emirates" },
        { code: "GB", label: "United Kingdom" },
        { code: "US", label: "United States" },
        { code: "UM", label: "United States Minor Outlying Islands" },
        { code: "UY", label: "Uruguay" },
        { code: "UZ", label: "Uzbekistan" },
        { code: "VU", label: "Vanuatu" },
        { code: "VE", label: "Venezuela, Bolivarian Republic of" },
        { code: "VN", label: "Viet Nam" },
        { code: "VG", label: "Virgin Islands, British" },
        { code: "VI", label: "Virgin Islands, U.S." },
        { code: "WF", label: "Wallis and Futuna" },
        { code: "EH", label: "Western Sahara" },
        { code: "YE", label: "Yemen" },
        { code: "ZM", label: "Zambia" },
        { code: "ZW", label: "Zimbabwe" }
    ];

    const euCountries = [
        "AT", // Austria
        "BE", // Belgium
        "BG", // Bulgaria
        "HR", // Croatia
        "CY", // Cyprus
        "CZ", // Czech Republic
        "DK", // Denmark
        "EE", // Estonia
        "FI", // Finland
        "FR", // France
        "DE", // Germany
        "GR", // Greece
        "HU", // Hungary
        "IE", // Ireland
        "IT", // Italy
        "LV", // Latvia
        "LT", // Lithuania
        "LU", // Luxembourg
        "MT", // Malta
        "NL", // Netherlands
        "PL", // Poland
        "PT", // Portugal
        "RO", // Romania
        "SK", // Slovakia
        "SI", // Slovenia
        "ES", // Spain
        "SE"  // Sweden
    ]

    // Cette fonction met à jour un champ du formulaire
    const handleChange = (fieldName) => (event) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: event.target.value,
        }));
    };

    useEffect(() => {
        if (formData.country == "FR") {
            setNeedVat(true)
            fetch('/create-checkout-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ currency: currency, withTax: true })
            })
                .then((response) => response.json())
                .then((json) => {
                    console.log(json)
                    setClientSecret(json.client_secret)
                    setAmount(json.amount_subtotal)
                    setDiscountAmount(json.total_details.amount_discount)
                    setTaxAmount(json.total_details.amount_tax)
                    setCurrency(json.currency)
                    if (childRef.current) {
                        childRef.current.callChildFunction();
                    }
                })
        }
        else if (euCountries.includes(formData.country) == false) {
            setNeedVat(false)
            fetch('/create-checkout-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ currency: currency, withTax: false })
            })
                .then((response) => response.json())
                .then((json) => {
                    setClientSecret(json.client_secret)
                    setAmount(json.amount_subtotal)
                    setDiscountAmount(json.total_details.amount_discount)
                    setTaxAmount(json.total_details.amount_tax)
                    setCurrency(json.currency)
                    if (childRef.current) {
                        childRef.current.callChildFunction();
                    }
                })
        }
        else if (formData.country != "") {
            let tmpNeedVat = false
            fetch('/validate-vat', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ countryCode: formData.country, vatNumber: formData.vat.slice(2) })
            })
                .then((response) => response.json())
                .then((json) => {
                    if (json.error || json.valid == false) {
                        setError('Invalid VAT number.');
                    } else {
                        setError(null)
                    }
                    if (formData.country == "FR" || (json.valid == false || json.error) && euCountries.includes(formData.country)) {
                        tmpNeedVat = true
                    }
                    setNeedVat(tmpNeedVat)
                    if (needVat != tmpNeedVat) {
                        fetch('/create-checkout-session', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ currency: currency, withTax: tmpNeedVat })
                        })
                            .then((response) => response.json())
                            .then((json) => {
                                setClientSecret(json.client_secret)
                                setAmount(json.amount_subtotal)
                                setDiscountAmount(json.total_details.amount_discount)
                                setTaxAmount(json.total_details.amount_tax)
                                setCurrency(json.currency)
                                if (childRef.current) {
                                    childRef.current.callChildFunction();
                                }
                            })
                    }
                })
        }
    }, [formData.vat, formData.country]) //formData.vat,

    const onCoupon = (session_id) => {
        fetch(`/get-checkout-session?sessionId=${session_id}`, { method: 'GET' })
            .then((response) => response.json())
            .then((json) => {
                console.log(json)
                setDiscountAmount(json.total_details.amount_discount)
                setTaxAmount(json.total_details.amount_tax)
            })
    }

    const saveCode = (draft) => {
        setCode(draft);
    }

    const onChangeCurrency = (currency) => {

        fetch('/create-checkout-session', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ currency: currency, withTax: needVat })
        })
            .then((response) => response.json())
            .then((json) => {
                setClientSecret(json.client_secret)
                setAmount(json.amount_subtotal)
                setDiscountAmount(json.total_details.amount_discount)
                setCurrency(json.currency)
            })
    }

    const childRef = useRef();

    //Else -> Forms
    return (
        <ThemeProvider theme={defaultTheme}>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />

            <>
                <Background />
                <WatermarkLogo src={arioneoLogo} alt="Arioneo watermark" />
                <TransparentNavbar position="fixed">
                    <Toolbar>
                        {/* Left Home Button */}
                        <Link to={t("home_link")}>
                            <HomeButton>
                                <TransUpperCase text="home" />
                            </HomeButton>
                        </Link>

                        {/* Center Logo */}
                        <CenterContainer>
                            <Logo src={logoImage} alt="Logo" />
                        </CenterContainer>

                        {/* Right Button */}
                        <Link to={t("contact_link")}>
                            <ContactButton><TransUpperCase text="contact_us" /></ContactButton>
                        </Link>

                        {/* Flag Buttons */}
                        <FlagButton>
                            <img src={frenchFlag} alt="French Flag" onClick={() => changeLanguage("fr")} />
                        </FlagButton>
                        <FlagButton>
                            <img src={britishFlag} alt="British Flag" onClick={() => changeLanguage("en")} />
                        </FlagButton>
                    </Toolbar>
                </TransparentNavbar>

                {/* Split Screen Layout */}
                {/* Centered Grid Layout */}
                {clientSecret && (
                    <CheckoutProvider
                        stripe={stripe}
                        options={{ clientSecret }}
                        key={clientSecret}
                    >

                        <CenteredGrid container spacing={8}>
                            <Grid item xs={12} md={6}>
                                <InfoBox>
                                    <List>
                                        {[
                                            "eqsce_line1",
                                            "eqsce_line2",
                                            "eqsce_line3",
                                            "eqsce_line4",
                                            "eqsce_line5",
                                            "eqsce_line6",
                                            "eqsce_line7",
                                        ].map((item, index) => (
                                            <ListItem key={index} sx={{ paddingY: 0 }}>
                                                <ListItemText
                                                    // Use `primaryTypographyProps` to allow HTML rendering
                                                    primaryTypographyProps={{
                                                        fontSize: '1.5rem',
                                                        fontFamily: "Montserrat, sans-serif",
                                                        fontWeight: 400,
                                                        component: "div",  // so it renders HTML tags
                                                    }}
                                                    primary={
                                                        // <div dangerouslySetInnerHTML={{ __html: `• ${item}` }} />
                                                        <>
                                                            • <TransHTML text={item} />
                                                        </>
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                    {(taxAmount > 0 && discountAmount == 0) && (
                                        <PriceBox > <Trans text="price" />: <CurrencySelection currency={currency.toUpperCase()} onChangeCurrency={onChangeCurrency}></CurrencySelection>{((amount + taxAmount) / 100.0).toFixed(2)}
                                            <SmallerPrice><Trans text="incl_vat" /></SmallerPrice>
                                        </PriceBox>
                                    )}
                                    {(taxAmount > 0 && discountAmount > 0) && (
                                        <PriceBox > <Trans text="price" />: <CurrencySelection currency={currency.toUpperCase()} onChangeCurrency={onChangeCurrency}></CurrencySelection><StrikePrice>{((amount + taxAmount) / 100.0).toFixed(2)}</StrikePrice> <Price>{((amount - discountAmount + taxAmount) / 100.0).toFixed(2)}</Price>
                                            <SmallerPrice><Trans text="incl_vat" /></SmallerPrice>
                                        </PriceBox>

                                    )}
                                    {(taxAmount == 0 && discountAmount > 0) && (
                                        <PriceBox > <Trans text="price" />: <CurrencySelection currency={currency.toUpperCase()} onChangeCurrency={onChangeCurrency}></CurrencySelection><StrikePrice>{((amount) / 100.0).toFixed(2)}</StrikePrice> <Price>{((amount - discountAmount) / 100.0).toFixed(2)}</Price>
                                            <SmallerPrice><Trans text="excl_vat" /></SmallerPrice>
                                        </PriceBox>

                                    )}
                                    {(taxAmount == 0 && discountAmount == 0) && (
                                        <PriceBox > <Trans text="price" />: <CurrencySelection currency={currency.toUpperCase()} onChangeCurrency={onChangeCurrency}></CurrencySelection>{(amount / 100.0).toFixed(2)}
                                            <SmallerPrice><Trans text="excl_vat" /></SmallerPrice>
                                        </PriceBox>
                                    )}
                                    <PromotionCode onCoupon={onCoupon} code={code} saveCode={saveCode} ref={childRef}></PromotionCode>

                                </InfoBox>
                            </Grid>

                            <Grid item xs={12} md={6}>

                                <FormContainer>
                                    <Typography variant="h5" fontWeight="bold" fontFamily="Montserrat, sans-serif" marginBottom={2} align="center">
                                        <Trans text="secure_payment_online" />
                                    </Typography>
                                    <Stack>
                                        <Grid container spacing={1} marginBottom={1}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    label={<Trans text={"firstname"} />}
                                                    value={formData.firstName}
                                                    onChange={handleChange("firstName")}
                                                    size="small"
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    label={<Trans text={"lastname"} />}
                                                    value={formData.lastName}
                                                    onChange={handleChange("lastName")}
                                                    size="small"
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    label={<Trans text={"company"} />}
                                                    value={formData.company}
                                                    onChange={handleChange("company")}
                                                    size="small"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    label={<Trans text={"vat_number"} />}
                                                    value={formData.vat}
                                                    onChange={handleChange("vat")}
                                                    size="small"
                                                    error={Boolean(error)}
                                                    helperText={error}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label={<Trans text={"address_line_1"} />}
                                                    value={formData.address1}
                                                    onChange={handleChange("address1")}
                                                    size="small"
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label={<Trans text={"address_line_2"} />}
                                                    value={formData.address2}
                                                    onChange={handleChange("address2")}
                                                    size="small"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    label={<Trans text={"zipcode"} />}
                                                    value={formData.zip}
                                                    onChange={handleChange("zip")}
                                                    size="small"
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    label={<Trans text={"city"} />}
                                                    value={formData.city}
                                                    onChange={handleChange("city")}
                                                    size="small"
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label" size="small" required>{<Trans text={"country"} />}</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        value={formData.country}
                                                        label={<Trans text={"country"} />}
                                                        onChange={handleChange("country")}
                                                        size="small"
                                                    >
                                                        {countries.map((c) => (
                                                            <MenuItem key={c.code} value={c.code}>
                                                                {c.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    label={<Trans text={"phone_number"} />}
                                                    value={formData.phone}
                                                    onChange={handleChange("phone")}
                                                    size="small"
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Email"
                                                    value={formData.email}
                                                    onChange={handleChange("email")}
                                                    size="small"
                                                    required
                                                />
                                            </Grid>
                                        </Grid>
                                        <CheckoutForm formData={formData} />
                                    </Stack>
                                </FormContainer>
                            </Grid>
                        </CenteredGrid>
                    </CheckoutProvider>
                )}

                <Stack direction="row" spacing={2} alignItems="center" >
                    <img
                        src={secureLogo}
                        alt="Arioneo"
                        style={{ width: 28, marginLeft: 20 }}
                    />
                    <Typography
                        variant="body1"
                        fontFamily="Montserrat, sans-serif"
                        sx={{ fontStyle: "italic" }}
                    >
                        <Trans text="secure" />
                    </Typography>
                </Stack>
            </>

        </ThemeProvider >
    );
}

export default Eqsc;
