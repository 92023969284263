import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

//COMPONENTS
import BillingInvoicesItemsTable from "./BillingInvoicesItemsTable.js";
import { TransUpperCase, Trans } from "./Trans.js";

function BillingInvoices({ invoices, creditNotes, cegidDocs, paymentMethods, customer }) {

    let filteredInvoices = []
    if (invoices != null) {
        filteredInvoices = invoices.filter((invoice) => { return invoice.status != "draft" })
    }

    return (
        <div>
            {invoices != null && (
                <div className="fixed-width">
                    <Stack spacing={2}>
                        <Typography variant="h5" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            <Trans text="billing_invoices" />
                        </Typography>
                        <BillingInvoicesItemsTable invoices={filteredInvoices} creditNotes={creditNotes} cegidDocs={cegidDocs} paymentMethods={paymentMethods} customer={customer}></BillingInvoicesItemsTable>
                    </Stack>
                </div>
            )
            }
        </div>
    );
}

export default BillingInvoices;
