//CSS
import "../styles/UserForms.css";
//COMPONENTS
import { TransUpperCase, Trans } from "./Trans.js";

function CountrySelect({
  name,
  label,
  required,
  value,
  onChange,
  errorMessage,
  disabled,
}) {
  return (
    <div className="hs-form__field">
      <label
        id={name + "-label"}
        htmlFor={name + "-input"}
        className="hs-form__field__label hs-form__field__label_required"
        data-required={required}
      >
        <span>{label}</span>
      </label>
      <select
        id={name + "-input"}
        aria-labelledby={name + "-label"}
        className="hs-form__field__input"
        name={name}
        aria-invalid="false"
        aria-required={required}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        <option label="-" value="">
          <Trans text="user_forms_billing_country_placeholder"></Trans>
        </option>
        <option label="Afghanistan" value="Afghanistan">
          Afghanistan
        </option>
        <option label="Albania" value="Albania">
          Albania
        </option>
        <option label="Algeria" value="Algeria">
          Algeria
        </option>
        <option label="American Samoa" value="American Samoa">
          American Samoa
        </option>
        <option label="Andorra" value="Andorra">
          Andorra
        </option>
        <option label="Angola" value="Angola">
          Angola
        </option>
        <option label="Anguilla" value="Anguilla">
          Anguilla
        </option>
        <option label="Antigua &amp; Barbuda" value="Antigua &amp; Barbuda">
          Antigua &amp; Barbuda
        </option>
        <option label="Argentina" value="Argentina">
          Argentina
        </option>
        <option label="Armenia" value="Armenia">
          Armenia
        </option>
        <option label="Aruba" value="Aruba">
          Aruba
        </option>
        <option label="Australia" value="Australia">
          Australia
        </option>
        <option label="Austria" value="Austria">
          Austria
        </option>
        <option label="Azerbaijan" value="Azerbaijan">
          Azerbaijan
        </option>
        <option label="Bahamas, The" value="Bahamas, The">
          Bahamas, The
        </option>
        <option label="Bahrain" value="Bahrain">
          Bahrain
        </option>
        <option label="Bangladesh" value="Bangladesh">
          Bangladesh
        </option>
        <option label="Barbados" value="Barbados">
          Barbados
        </option>
        <option label="Belarus" value="Belarus">
          Belarus
        </option>
        <option label="Belgium" value="Belgium">
          Belgium
        </option>
        <option label="Belize" value="Belize">
          Belize
        </option>
        <option label="Benin" value="Benin">
          Benin
        </option>
        <option label="Bermuda" value="Bermuda">
          Bermuda
        </option>
        <option label="Bhutan" value="Bhutan">
          Bhutan
        </option>
        <option label="Bolivia" value="Bolivia">
          Bolivia
        </option>
        <option
          label="Bosnia &amp; Herzegovina"
          value="Bosnia &amp; Herzegovina"
        >
          Bosnia &amp; Herzegovina
        </option>
        <option label="Botswana" value="Botswana">
          Botswana
        </option>
        <option label="Brazil" value="Brazil">
          Brazil
        </option>
        <option label="British Virgin Is." value="British Virgin Is.">
          British Virgin Is.
        </option>
        <option label="Brunei" value="Brunei">
          Brunei
        </option>
        <option label="Bulgaria" value="Bulgaria">
          Bulgaria
        </option>
        <option label="Burkina Faso" value="Burkina Faso">
          Burkina Faso
        </option>
        <option label="Burma" value="Burma">
          Burma
        </option>
        <option label="Burundi" value="Burundi">
          Burundi
        </option>
        <option label="Cambodia" value="Cambodia">
          Cambodia
        </option>
        <option label="Cameroon" value="Cameroon">
          Cameroon
        </option>
        <option label="Canada" value="Canada">
          Canada
        </option>
        <option label="Cape Verde" value="Cape Verde">
          Cape Verde
        </option>
        <option label="Cayman Islands" value="Cayman Islands">
          Cayman Islands
        </option>
        <option label="Central African Rep." value="Central African Rep.">
          Central African Rep.
        </option>
        <option label="Chad" value="Chad">
          Chad
        </option>
        <option label="Chile" value="Chile">
          Chile
        </option>
        <option label="China" value="China">
          China
        </option>
        <option label="Colombia" value="Colombia">
          Colombia
        </option>
        <option label="Comoros" value="Comoros">
          Comoros
        </option>
        <option label="Congo, Dem. Rep." value="Congo, Dem. Rep.">
          Congo, Dem. Rep.
        </option>
        <option label="Congo, Repub. of the" value="Congo, Repub. of the">
          Congo, Repub. of the
        </option>
        <option label="Cook Islands" value="Cook Islands">
          Cook Islands
        </option>
        <option label="Costa Rica" value="Costa Rica">
          Costa Rica
        </option>
        <option label="Cote d'Ivoire" value="Cote d'Ivoire">
          Cote d'Ivoire
        </option>
        <option label="Croatia" value="Croatia">
          Croatia
        </option>
        <option label="Cuba" value="Cuba">
          Cuba
        </option>
        <option label="Cyprus" value="Cyprus">
          Cyprus
        </option>
        <option label="Czech Republic" value="Czech Republic">
          Czech Republic
        </option>
        <option label="Denmark" value="Denmark">
          Denmark
        </option>
        <option label="Djibouti" value="Djibouti">
          Djibouti
        </option>
        <option label="Dominica" value="Dominica">
          Dominica
        </option>
        <option label="Dominican Republic" value="Dominican Republic">
          Dominican Republic
        </option>
        <option label="East Timor" value="East Timor">
          East Timor
        </option>
        <option label="Ecuador" value="Ecuador">
          Ecuador
        </option>
        <option label="Egypt" value="Egypt">
          Egypt
        </option>
        <option label="El Salvador" value="El Salvador">
          El Salvador
        </option>
        <option label="Equatorial Guinea" value="Equatorial Guinea">
          Equatorial Guinea
        </option>
        <option label="Eritrea" value="Eritrea">
          Eritrea
        </option>
        <option label="Estonia" value="Estonia">
          Estonia
        </option>
        <option label="Ethiopia" value="Ethiopia">
          Ethiopia
        </option>
        <option label="Faroe Islands" value="Faroe Islands">
          Faroe Islands
        </option>
        <option label="Fiji" value="Fiji">
          Fiji
        </option>
        <option label="Finland" value="Finland">
          Finland
        </option>
        <option label="France" value="France">
          France
        </option>
        <option label="French Guiana" value="French Guiana">
          French Guiana
        </option>
        <option label="French Polynesia" value="French Polynesia">
          French Polynesia
        </option>
        <option label="Gabon" value="Gabon">
          Gabon
        </option>
        <option label="Gambia, The" value="Gambia, The">
          Gambia, The
        </option>
        <option label="Gaza Strip" value="Gaza Strip">
          Gaza Strip
        </option>
        <option label="Georgia" value="Georgia">
          Georgia
        </option>
        <option label="Germany" value="Germany">
          Germany
        </option>
        <option label="Ghana" value="Ghana">
          Ghana
        </option>
        <option label="Gibraltar" value="Gibraltar">
          Gibraltar
        </option>
        <option label="Greece" value="Greece">
          Greece
        </option>
        <option label="Greenland" value="Greenland">
          Greenland
        </option>
        <option label="Grenada" value="Grenada">
          Grenada
        </option>
        <option label="Guadeloupe" value="Guadeloupe">
          Guadeloupe
        </option>
        <option label="Guam" value="Guam">
          Guam
        </option>
        <option label="Guatemala" value="Guatemala">
          Guatemala
        </option>
        <option label="Guernsey" value="Guernsey">
          Guernsey
        </option>
        <option label="Guinea" value="Guinea">
          Guinea
        </option>
        <option label="Guinea-Bissau" value="Guinea-Bissau">
          Guinea-Bissau
        </option>
        <option label="Guyana" value="Guyana">
          Guyana
        </option>
        <option label="Haiti" value="Haiti">
          Haiti
        </option>
        <option label="Honduras" value="Honduras">
          Honduras
        </option>
        <option label="Hong Kong" value="Hong Kong">
          Hong Kong
        </option>
        <option label="Hungary" value="Hungary">
          Hungary
        </option>
        <option label="Iceland" value="Iceland">
          Iceland
        </option>
        <option label="India" value="India">
          India
        </option>
        <option label="Indonesia" value="Indonesia">
          Indonesia
        </option>
        <option label="Iran" value="Iran">
          Iran
        </option>
        <option label="Iraq" value="Iraq">
          Iraq
        </option>
        <option label="Ireland" value="Ireland">
          Ireland
        </option>
        <option label="Isle of Man" value="Isle of Man">
          Isle of Man
        </option>
        <option label="Israel" value="Israel">
          Israel
        </option>
        <option label="Italy" value="Italy">
          Italy
        </option>
        <option label="Jamaica" value="Jamaica">
          Jamaica
        </option>
        <option label="Japan" value="Japan">
          Japan
        </option>
        <option label="Jersey" value="Jersey">
          Jersey
        </option>
        <option label="Jordan" value="Jordan">
          Jordan
        </option>
        <option label="Kazakhstan" value="Kazakhstan">
          Kazakhstan
        </option>
        <option label="Kenya" value="Kenya">
          Kenya
        </option>
        <option label="Kiribati" value="Kiribati">
          Kiribati
        </option>
        <option label="Korea, North" value="Korea, North">
          Korea, North
        </option>
        <option label="Korea, South" value="Korea, South">
          Korea, South
        </option>
        <option label="Kuwait" value="Kuwait">
          Kuwait
        </option>
        <option label="Kyrgyzstan" value="Kyrgyzstan">
          Kyrgyzstan
        </option>
        <option label="Laos" value="Laos">
          Laos
        </option>
        <option label="Latvia" value="Latvia">
          Latvia
        </option>
        <option label="Lebanon" value="Lebanon">
          Lebanon
        </option>
        <option label="Lesotho" value="Lesotho">
          Lesotho
        </option>
        <option label="Liberia" value="Liberia">
          Liberia
        </option>
        <option label="Libya" value="Libya">
          Libya
        </option>
        <option label="Liechtenstein" value="Liechtenstein">
          Liechtenstein
        </option>
        <option label="Lithuania" value="Lithuania">
          Lithuania
        </option>
        <option label="Luxembourg" value="Luxembourg">
          Luxembourg
        </option>
        <option label="Macau" value="Macau">
          Macau
        </option>
        <option label="Macedonia" value="Macedonia">
          Macedonia
        </option>
        <option label="Madagascar" value="Madagascar">
          Madagascar
        </option>
        <option label="Malawi" value="Malawi">
          Malawi
        </option>
        <option label="Malaysia" value="Malaysia">
          Malaysia
        </option>
        <option label="Maldives" value="Maldives">
          Maldives
        </option>
        <option label="Mali" value="Mali">
          Mali
        </option>
        <option label="Malta" value="Malta">
          Malta
        </option>
        <option label="Marshall Islands" value="Marshall Islands">
          Marshall Islands
        </option>
        <option label="Martinique" value="Martinique">
          Martinique
        </option>
        <option label="Mauritania" value="Mauritania">
          Mauritania
        </option>
        <option label="Mauritius" value="Mauritius">
          Mauritius
        </option>
        <option label="Mayotte" value="Mayotte">
          Mayotte
        </option>
        <option label="Mexico" value="Mexico">
          Mexico
        </option>
        <option label="Micronesia, Fed. St." value="Micronesia, Fed. St.">
          Micronesia, Fed. St.
        </option>
        <option label="Moldova" value="Moldova">
          Moldova
        </option>
        <option label="Monaco" value="Monaco">
          Monaco
        </option>
        <option label="Mongolia" value="Mongolia">
          Mongolia
        </option>
        <option label="Montserrat" value="Montserrat">
          Montserrat
        </option>
        <option label="Morocco" value="Morocco">
          Morocco
        </option>
        <option label="Mozambique" value="Mozambique">
          Mozambique
        </option>
        <option label="Namibia" value="Namibia">
          Namibia
        </option>
        <option label="Nauru" value="Nauru">
          Nauru
        </option>
        <option label="Nepal" value="Nepal">
          Nepal
        </option>
        <option label="Netherlands" value="Netherlands">
          Netherlands
        </option>
        <option label="Netherlands Antilles" value="Netherlands Antilles">
          Netherlands Antilles
        </option>
        <option label="New Caledonia" value="New Caledonia">
          New Caledonia
        </option>
        <option label="New Zealand" value="New Zealand">
          New Zealand
        </option>
        <option label="Nicaragua" value="Nicaragua">
          Nicaragua
        </option>
        <option label="Niger" value="Niger">
          Niger
        </option>
        <option label="Nigeria" value="Nigeria">
          Nigeria
        </option>
        <option label="N. Mariana Islands" value="N. Mariana Islands">
          N. Mariana Islands
        </option>
        <option label="Norway" value="Norway">
          Norway
        </option>
        <option label="Oman" value="Oman">
          Oman
        </option>
        <option label="Pakistan" value="Pakistan">
          Pakistan
        </option>
        <option label="Palau" value="Palau">
          Palau
        </option>
        <option label="Panama" value="Panama">
          Panama
        </option>
        <option label="Papua New Guinea" value="Papua New Guinea">
          Papua New Guinea
        </option>
        <option label="Paraguay" value="Paraguay">
          Paraguay
        </option>
        <option label="Peru" value="Peru">
          Peru
        </option>
        <option label="Philippines" value="Philippines">
          Philippines
        </option>
        <option label="Poland" value="Poland">
          Poland
        </option>
        <option label="Portugal" value="Portugal">
          Portugal
        </option>
        <option label="Puerto Rico" value="Puerto Rico">
          Puerto Rico
        </option>
        <option label="Qatar" value="Qatar">
          Qatar
        </option>
        <option label="Reunion" value="Reunion">
          Reunion
        </option>
        <option label="Romania" value="Romania">
          Romania
        </option>
        <option label="Russia" value="Russia">
          Russia
        </option>
        <option label="Rwanda" value="Rwanda">
          Rwanda
        </option>
        <option label="Saint Helena" value="Saint Helena">
          Saint Helena
        </option>
        <option label="Saint Kitts &amp; Nevis" value="Saint Kitts &amp; Nevis">
          Saint Kitts &amp; Nevis
        </option>
        <option label="Saint Lucia" value="Saint Lucia">
          Saint Lucia
        </option>
        <option
          label="St Pierre &amp; Miquelon"
          value="St Pierre &amp; Miquelon"
        >
          St Pierre &amp; Miquelon
        </option>
        <option
          label="Saint Vincent and the Grenadines"
          value="Saint Vincent and the Grenadines"
        >
          Saint Vincent and the Grenadines
        </option>
        <option label="Samoa" value="Samoa">
          Samoa
        </option>
        <option label="San Marino" value="San Marino">
          San Marino
        </option>
        <option label="Sao Tome &amp; Principe" value="Sao Tome &amp; Principe">
          Sao Tome &amp; Principe
        </option>
        <option label="Saudi Arabia" value="Saudi Arabia">
          Saudi Arabia
        </option>
        <option label="Senegal" value="Senegal">
          Senegal
        </option>
        <option label="Serbia" value="Serbia">
          Serbia
        </option>
        <option label="Seychelles" value="Seychelles">
          Seychelles
        </option>
        <option label="Sierra Leone" value="Sierra Leone">
          Sierra Leone
        </option>
        <option label="Singapore" value="Singapore">
          Singapore
        </option>
        <option label="Slovakia" value="Slovakia">
          Slovakia
        </option>
        <option label="Slovenia" value="Slovenia">
          Slovenia
        </option>
        <option label="Solomon Islands" value="Solomon Islands">
          Solomon Islands
        </option>
        <option label="Somalia" value="Somalia">
          Somalia
        </option>
        <option label="South Africa" value="South Africa">
          South Africa
        </option>
        <option label="Spain" value="Spain">
          Spain
        </option>
        <option label="Sri Lanka" value="Sri Lanka">
          Sri Lanka
        </option>
        <option label="Sudan" value="Sudan">
          Sudan
        </option>
        <option label="Suriname" value="Suriname">
          Suriname
        </option>
        <option label="Swaziland" value="Swaziland">
          Swaziland
        </option>
        <option label="Sweden" value="Sweden">
          Sweden
        </option>
        <option label="Switzerland" value="Switzerland">
          Switzerland
        </option>
        <option label="Syria" value="Syria">
          Syria
        </option>
        <option label="Taiwan" value="Taiwan">
          Taiwan
        </option>
        <option label="Tajikistan" value="Tajikistan">
          Tajikistan
        </option>
        <option label="Tanzania" value="Tanzania">
          Tanzania
        </option>
        <option label="Thailand" value="Thailand">
          Thailand
        </option>
        <option label="Togo" value="Togo">
          Togo
        </option>
        <option label="Tonga" value="Tonga">
          Tonga
        </option>
        <option label="Trinidad &amp; Tobago" value="Trinidad &amp; Tobago">
          Trinidad &amp; Tobago
        </option>
        <option label="Tunisia" value="Tunisia">
          Tunisia
        </option>
        <option label="Turkey" value="Turkey">
          Turkey
        </option>
        <option label="Turkmenistan" value="Turkmenistan">
          Turkmenistan
        </option>
        <option label="Turks &amp; Caicos Is" value="Turks &amp; Caicos Is">
          Turks &amp; Caicos Is
        </option>
        <option label="Tuvalu" value="Tuvalu">
          Tuvalu
        </option>
        <option label="Uganda" value="Uganda">
          Uganda
        </option>
        <option label="Ukraine" value="Ukraine">
          Ukraine
        </option>
        <option label="United Arab Emirates" value="United Arab Emirates">
          United Arab Emirates
        </option>
        <option label="United Kingdom" value="United Kingdom">
          United Kingdom
        </option>
        <option label="United States" value="United States">
          United States
        </option>
        <option label="Uruguay" value="Uruguay">
          Uruguay
        </option>
        <option label="Uzbekistan" value="Uzbekistan">
          Uzbekistan
        </option>
        <option label="Vanuatu" value="Vanuatu">
          Vanuatu
        </option>
        <option label="Venezuela" value="Venezuela">
          Venezuela
        </option>
        <option label="Vietnam" value="Vietnam">
          Vietnam
        </option>
        <option label="Virgin Islands" value="Virgin Islands">
          Virgin Islands
        </option>
        <option label="Wallis and Futuna" value="Wallis and Futuna">
          Wallis and Futuna
        </option>
        <option label="West Bank" value="West Bank">
          West Bank
        </option>
        <option label="Western Sahara" value="Western Sahara">
          Western Sahara
        </option>
        <option label="Yemen" value="Yemen">
          Yemen
        </option>
        <option label="Zambia" value="Zambia">
          Zambia
        </option>
        <option label="Zimbabwe" value="Zimbabwe">
          Zimbabwe
        </option>
      </select>
      {errorMessage && (
        <div
          id="email__error"
          class="hs-form__field__alert hs-form__field__alert_error"
        >
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  );
}

export default CountrySelect;
