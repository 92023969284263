import i18n from "../locale/i18n";
import { useState, useEffect, useRef } from "react";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
//CSS
import "../styles/App.css";
//COMPONENTS
import Topbar from "./Topbar.js";
import Quote from "./Quote.js";
import Footer from "./Footer.js";
import FullForm from "./FullForm.js";
import BankTransfert from "./BankTransfert.js";
import Following from "./Following.js";
import QuoteExpired from "./QuoteExpired.js";
import QuoteTemplates from "./QuoteTemplates";

//DATAS
import { SALES } from "../datas/sales";

function Page({ dealId }) {
  const [state, setState] = useState({
    deal: null,
    company: null,
    salesOwner: null,
    lineItems: null,
  });

  const componentRef = useRef();



  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://js.hs-scripts.com/4217512.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    async function callAPI() {
      const responseDeal = await fetch("/getDeal?safe=true&dealId=" + dealId);
      const jsonDeal = await responseDeal.json();

      const responseCompanyAssociation = await fetch("/getDealCompaniesAssociations?dealId=" + jsonDeal.hs_object_id);
      const jsonCompanyAssociation = await responseCompanyAssociation.json();

      var companies = [];

      for (const companyAssociation of jsonCompanyAssociation.results) {
        const company = await fetch(
          "/getCompany?companyId=" + companyAssociation.id
        );
        const jsonCompany = await company.json();
        companies.push(jsonCompany);
      }

      const responseLineItemsAssociation = await fetch(
        "/getDealLineItemsAssociations?dealId=" + jsonDeal.hs_object_id
      );
      const jsonLineItemsAssociation = await responseLineItemsAssociation.json();

      var lineItems = [];

      for (const lineItemAssociation of jsonLineItemsAssociation.results) {
        const lineItem = await fetch(
          "/getLineItem?lineItemId=" + lineItemAssociation.id
        );
        const jsonLineItem = await lineItem.json();
        lineItems.push(jsonLineItem);
      }

      setState({
        deal: jsonDeal,
        company: companies.length > 0 ? companies[0] : [],
        salesOwner: SALES[jsonDeal.hubspot_owner_id],
        lineItems: lineItems,
      });
    }

    callAPI();
  }, [dealId]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  if (state.deal === null) {
    return <div></div>;
  }

  if (state.lineItems && state.lineItems.length === 0) {
    return (
      <QuoteTemplates
        deal={state.deal}
        company={state.company}
        salesOwner={state.salesOwner}
      ></QuoteTemplates>
    );
  }

  // //Check if the payment is received
  if (state.deal && state.deal.paiement_received === "YES") {
    return (
      <Following
        deal={state.deal}
        lineItems={state.lineItems}
        company={state.company}
        salesOwner={state.salesOwner}
      ></Following>
    );
  } else if (state.deal && state.deal.paiement_received === "PARTIALLY") {
    return (
      <Following
        deal={state.deal}
        lineItems={state.lineItems}
        company={state.company}
        salesOwner={state.salesOwner}
      ></Following>
    );
  } else {
    //NO

    //Check if the link exists
    if (state.deal && state.deal.payment_link === "progress") {
      alert(i18n.t("actions_in_progress"));
    } else if (
      state.deal &&
      state.deal.payment_link &&
      state.deal.payment_link.length > 0
    ) {
      if (state.deal.paiement_type === "Bank Transfer") {
        return (
          <BankTransfert
            deal={state.deal}
            company={state.company}
            salesOwner={state.salesOwner}
            lineItems={state.lineItems}
          ></BankTransfert>
        );
      } else {
        window.location.href = state.deal.payment_link;
      }
    }
  }

  //Check if the quote is not expired
  if (state.deal && state.deal.date_d_expiration_du_devis) {
    if (state.deal.date_d_expiration_du_devis.length > 0) {
      let date = new Date(state.deal.date_d_expiration_du_devis ?? new Date());
      if (new Date() >= date) {
        return <QuoteExpired />;
      }
    }
  }

  //Else -> Forms
  return (
    <div className="page">
      <div className="quote-page-container valid">
        <div className="original-quote-template">
          <div className="brofile brofile-sticky">
            <div
              wrap="wrap"
              direction="row"
              className="UIFlex__StyledFlex-gi5hfq-0 jAMQbh private-flex Brofile__ResponsiveFlex-sc-1vi4x4x-0 fFinRi brofile-content fixed-width"
            >
              <img
                alt=""
                className="private-image company-logo m-y-auto img-responsive private-image--responsive"
                src="https://cdn2.hubspot.net/hubfs/4217512/Noir-fd%20transparent-signature.png"
              ></img>
              <div className="text-center m-y-auto">
                <ul className="uiList private-list--inline quote-actions private-list--unstyled">
                  <li className="uiListItem private-list__item">
                    <button className="flag-button">
                      <img
                        src="https://img.icons8.com/color/96/000000/great-britain-circular.png"
                        alt="GB"
                        width="48"
                        height="48"
                        onClick={() => changeLanguage("en")}
                      />
                    </button>
                  </li>
                  <li className="uiListItem private-list__item">
                    <button className="flag-button">
                      <img
                        src="https://img.icons8.com/color/96/000000/france-circular.png"
                        width="48"
                        height="48"
                        alt="FR"
                        onClick={() => changeLanguage("fr")}
                      />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div ref={componentRef}>
            <Topbar
              deal={state.deal}
              company={state.company}
              salesOwner={state.salesOwner}
            ></Topbar>

            {state.deal && (
              <Quote
                deal={state.deal}
                lineItems={state.lineItems}
                company={state.company}
                expirationDate={state.deal.date_d_expiration_du_devis}
              ></Quote>
            )}
          </div>

          <FullForm
            deal={state.deal}
            lineItems={state.lineItems}
            company={state.company}
            expirationDate={state.deal.date_d_expiration_du_devis}
            salesOwner={state.salesOwner}
            pageRef={componentRef.current}
          ></FullForm>

          <Footer
            deal={state.deal}
            salesOwner={state.salesOwner}
          ></Footer>
        </div>
      </div>
    </div>
  );
}

export default Page;
